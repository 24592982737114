// OrderDetails.js
import React from "react";
import "./OrderDetails.css"; // Create a CSS file for styling the table

function OrderDetails({
  orderNumber,
  setOrderNumber,
  pickupDate,
  setPickupDate,
  pickupLocation,
  setPickupLocation,
  deliveryLocation,
  setDeliveryLocation,
  deliveryDate,
  setDeliveryDate,
  rate,
  setRate,
  currency,
  setCurrency,
  priceGiven,
  setPriceGiven,
  tripType,
  setTripType,
  equipment,
  setEquipment,
  status,
  setStatus,
  accountingEmail,
  setAccountingEmail,
  dispatcherEmail,
  setDispatcherEmail,
  pickups,
  setPickups,
  deliveries,
  setDeliveries,
  loadGivingCompany,
  setLoadGivingCompany,
  onSave,
}) {
  // Handlers for dynamic pickup and delivery locations
  const handlePickupChange = (index, field, value) => {
    const updatedPickups = [...pickups];
    updatedPickups[index][field] = value;
    setPickups(updatedPickups);
  };

  const handleDeliveryChange = (index, field, value) => {
    const updatedDeliveries = [...deliveries];
    updatedDeliveries[index][field] = value;
    setDeliveries(updatedDeliveries);
  };

  const addPickup = () => {
    setPickups([
      ...pickups,
      { locationName: "", streetAddress: "", city: "", state: "", zip: "" },
    ]);
  };

  const removePickup = (index) => {
    const updatedPickups = pickups.filter((_, i) => i !== index);
    setPickups(updatedPickups);
  };

  const addDelivery = () => {
    setDeliveries([
      ...deliveries,
      { locationName: "", streetAddress: "", city: "", state: "", zip: "" },
    ]);
  };

  const removeDelivery = (index) => {
    const updatedDeliveries = deliveries.filter((_, i) => i !== index);
    setDeliveries(updatedDeliveries);
  };

  return (
    <div className="order-details-container">
      <h2>Order Details</h2>
      <table className="order-details-table">
        <tbody>
          {/* Load Reference Section */}
          <tr>
            <th colSpan="2">Load Reference</th>
          </tr>
          <tr>
            <td>Order Number:</td>
            <td>
              <input
                type="text"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Pickup Date:</td>
            <td>
              <input
                type="date"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Pickup Location:</td>
            <td>
              <input
                type="text"
                value={pickupLocation}
                onChange={(e) => setPickupLocation(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Delivery Date:</td>
            <td>
              <input
                type="date"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Delivery Location:</td>
            <td>
              <input
                type="text"
                value={deliveryLocation}
                onChange={(e) => setDeliveryLocation(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Rate:</td>
            <td>
              <input
                type="number"
                value={rate}
                onChange={(e) => setRate(parseFloat(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>Currency:</td>
            <td>
              <input
                type="text"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              />
            </td>
          </tr>

          {/* Load Giving Company Section */}
          <tr>
            <th colSpan="2">Load Giving Company</th>
          </tr>
          <tr>
            <td>Company Name:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.companyName}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    companyName: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Street Address:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.streetAddress}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    streetAddress: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>City:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.city}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    city: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>State:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.state}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    state: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>ZIP Code:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.zip}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    zip: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Phone Number:</td>
            <td>
              <input
                type="text"
                value={loadGivingCompany.phoneNumber}
                onChange={(e) =>
                  setLoadGivingCompany({
                    ...loadGivingCompany,
                    phoneNumber: e.target.value,
                  })
                }
              />
            </td>
          </tr>

          {/* Contact Emails Section */}
          <tr>
            <th colSpan="2">Contact Emails</th>
          </tr>
          <tr>
            <td>Accounting Email:</td>
            <td>
              <input
                type="email"
                value={accountingEmail}
                onChange={(e) => setAccountingEmail(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Dispatcher Email:</td>
            <td>
              <input
                type="email"
                value={dispatcherEmail}
                onChange={(e) => setDispatcherEmail(e.target.value)}
              />
            </td>
          </tr>

          {/* Pickup Locations Section */}
          <tr>
            <th colSpan="2">Pickup Locations</th>
          </tr>
          {pickups.map((pickup, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>Location Name:</td>
                <td>
                  <input
                    type="text"
                    value={pickup.locationName}
                    onChange={(e) =>
                      handlePickupChange(index, "locationName", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Street Address:</td>
                <td>
                  <input
                    type="text"
                    value={pickup.streetAddress}
                    onChange={(e) =>
                      handlePickupChange(index, "streetAddress", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>City:</td>
                <td>
                  <input
                    type="text"
                    value={pickup.city}
                    onChange={(e) =>
                      handlePickupChange(index, "city", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>State:</td>
                <td>
                  <input
                    type="text"
                    value={pickup.state}
                    onChange={(e) =>
                      handlePickupChange(index, "state", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>ZIP Code:</td>
                <td>
                  <input
                    type="text"
                    value={pickup.zip}
                    onChange={(e) =>
                      handlePickupChange(index, "zip", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button onClick={() => removePickup(index)}>
                    Remove Pickup
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td colSpan="2">
              <button onClick={addPickup}>Add Pickup Location</button>
            </td>
          </tr>

          {/* Delivery Locations Section */}
          <tr>
            <th colSpan="2">Delivery Locations</th>
          </tr>
          {deliveries.map((delivery, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>Location Name:</td>
                <td>
                  <input
                    type="text"
                    value={delivery.locationName}
                    onChange={(e) =>
                      handleDeliveryChange(
                        index,
                        "locationName",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Street Address:</td>
                <td>
                  <input
                    type="text"
                    value={delivery.streetAddress}
                    onChange={(e) =>
                      handleDeliveryChange(
                        index,
                        "streetAddress",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>City:</td>
                <td>
                  <input
                    type="text"
                    value={delivery.city}
                    onChange={(e) =>
                      handleDeliveryChange(index, "city", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>State:</td>
                <td>
                  <input
                    type="text"
                    value={delivery.state}
                    onChange={(e) =>
                      handleDeliveryChange(index, "state", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>ZIP Code:</td>
                <td>
                  <input
                    type="text"
                    value={delivery.zip}
                    onChange={(e) =>
                      handleDeliveryChange(index, "zip", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button onClick={() => removeDelivery(index)}>
                    Remove Delivery
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td colSpan="2">
              <button onClick={addDelivery}>Add Delivery Location</button>
            </td>
          </tr>

          {/* Additional Details Section */}
          <tr>
            <th colSpan="2">Additional Details</th>
          </tr>
          <tr>
            <td>Price Given:</td>
            <td>
              <input
                type="number"
                value={priceGiven}
                onChange={(e) => setPriceGiven(parseFloat(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>Trip Type:</td>
            <td>
              <input
                type="text"
                value={tripType}
                onChange={(e) => setTripType(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Equipment:</td>
            <td>
              <input
                type="text"
                value={equipment}
                onChange={(e) => setEquipment(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <input
                type="text"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>

      {/* Save Button */}
      <button onClick={() => onSave()}>Save Order Details</button>
    </div>
  );
}

export default OrderDetails;
