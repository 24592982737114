import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Box,
  Typography,
  Paper,
  Toolbar,
  CircularProgress,
  Modal,
  Slider,
  IconButton,
  TextField,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SideNavBar from "./SideNavBar";
import "react-quill/dist/quill.snow.css"; // React Quill CSS
import ReactQuill from "react-quill";
import { PDFDocument } from "pdf-lib"; // For merging and embedding pages
import CropEasy from "react-easy-crop"; // For cropping
import CloseIcon from "@mui/icons-material/Close";
import "./CreateInvoice.css";

// -------------- NEW IMPORT for the PDF Editor --------------
import PdfEditor from "./PdfEditor";

// ======================
// Helper: create a canvas from the cropped area
// so we can produce a Blob of the cropped image
// (adapted from typical react-easy-crop usage).
// ======================
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues
    image.src = url;
  });

async function getCroppedImg(imageSrc, croppedAreaPixels) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Set canvas to cropped area dimensions
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(file);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, "image/jpeg");
  });
}

// ==================================================
// 1) Convert to grayscale & auto-level
// ==================================================
function grayscaleAndAutoLevel(imageData) {
  const { data } = imageData;
  let minGray = 255,
    maxGray = 0;

  // Convert to grayscale, track min/max
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i],
      g = data[i + 1],
      b = data[i + 2];
    const gray = Math.round((r + g + b) / 3);
    data[i] = gray;
    data[i + 1] = gray;
    data[i + 2] = gray;
    if (gray < minGray) minGray = gray;
    if (gray > maxGray) maxGray = gray;
  }

  // Auto-level (stretch histogram)
  const range = maxGray - minGray || 1;
  for (let i = 0; i < data.length; i += 4) {
    const g = data[i];
    // scale to 0..255
    const scaled = ((g - minGray) / range) * 255;
    data[i] = scaled;
    data[i + 1] = scaled;
    data[i + 2] = scaled;
  }
}

// ==================================================
// 2) Sharpen Helper (3×3 kernel)
//    A simple sharpen kernel:
//          [  0, -1,  0 ]
//          [ -1,  5, -1 ]
//          [  0, -1,  0 ]
// ==================================================
function sharpenGrayscale(imageData) {
  const { data, width, height } = imageData;
  const output = new Uint8ClampedArray(data.length);

  const kernel = [
    [0, -1, 0],
    [-1, 5, -1],
    [0, -1, 0],
  ];

  const idx = (x, y) => 4 * (y * width + x);

  // For each pixel (excluding borders)
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      let sum = 0;
      // Convolution
      for (let ky = -1; ky <= 1; ky++) {
        for (let kx = -1; kx <= 1; kx++) {
          const pixelIndex = idx(x + kx, y + ky);
          sum += data[pixelIndex] * kernel[ky + 1][kx + 1];
        }
      }
      // clamp to [0..255]
      sum = Math.min(255, Math.max(0, sum));

      const outIndex = idx(x, y);
      output[outIndex + 0] = sum;
      output[outIndex + 1] = sum;
      output[outIndex + 2] = sum;
      output[outIndex + 3] = 255;
    }
  }

  // Copy borders as-is
  for (let x = 0; x < width; x++) {
    // top row
    let topIdx = idx(x, 0);
    output[topIdx] = data[topIdx];
    output[topIdx + 1] = data[topIdx + 1];
    output[topIdx + 2] = data[topIdx + 2];
    output[topIdx + 3] = data[topIdx + 3];

    // bottom row
    let botIdx = idx(x, height - 1);
    output[botIdx] = data[botIdx];
    output[botIdx + 1] = data[botIdx + 1];
    output[botIdx + 2] = data[botIdx + 2];
    output[botIdx + 3] = data[botIdx + 3];
  }
  for (let y = 0; y < height; y++) {
    // left col
    let leftIdx = idx(0, y);
    output[leftIdx] = data[leftIdx];
    output[leftIdx + 1] = data[leftIdx + 1];
    output[leftIdx + 2] = data[leftIdx + 2];
    output[leftIdx + 3] = data[leftIdx + 3];

    // right col
    let rightIdx = idx(width - 1, y);
    output[rightIdx] = data[rightIdx];
    output[rightIdx + 1] = data[rightIdx + 1];
    output[rightIdx + 2] = data[rightIdx + 2];
    output[rightIdx + 3] = data[rightIdx + 3];
  }

  // Copy sharpened data back to imageData
  for (let i = 0; i < data.length; i++) {
    data[i] = output[i];
  }
}

// ==================================================
// Combined "scan" effect: grayscale -> auto-level -> sharpen
// ==================================================
const processImageFile = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      // Create canvas
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      // Draw full image
      ctx.drawImage(img, 0, 0);

      // Extract pixel data
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      // 1) Grayscale + auto-level
      grayscaleAndAutoLevel(imageData);

      // 2) Sharpen
      sharpenGrayscale(imageData);

      // Put it back
      ctx.putImageData(imageData, 0, 0);

      // Convert to blob
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Canvas conversion failed."));
          }
          URL.revokeObjectURL(url);
        },
        file.type,
        1
      );
    };

    img.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };

    img.src = url;
  });
};

function Invoicing() {
  const { orderNumber } = useParams();
  const navigate = useNavigate();

  // State variables for PDF and file upload.
  const [podFile, setPodFile] = useState(null);
  const [lcUrl, setLcUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // State variables for load details
  const [pickupDate, setPickupDate] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [rate, setRate] = useState(0.0);
  const [currency, setCurrency] = useState("");
  const [accountingEmail, setAccountingEmail] = useState("");
  const [dispatcherEmail, setDispatcherEmail] = useState("");
  const [pickups, setPickups] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [customerOrderNumber, setCustomerOrderNumber] = useState("");
  const [loadGivingCompany, setLoadGivingCompany] = useState({
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
  });
  const [userId, setUserId] = useState("");
  const [invoicePdfUrl, setInvoicePdfUrl] = useState("");

  // Terms & Conditions
  const [terms, setTerms] = useState("");
  const [showTnC, setShowTnC] = useState(false);

  // We store all selected image files here so we can crop them first or skip cropping.
  const [imageFiles, setImageFiles] = useState([]);

  // For the cropping modal:
  const [openCropModal, setOpenCropModal] = useState(false);
  const [cropImageObj, setCropImageObj] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Let the user resize the bounding box using a slider
  const [cropSize, setCropSize] = useState({ width: 350, height: 300 });

  // Store the final combined PDF (after "Combine PDFs")
  const [combinedPdfFile, setCombinedPdfFile] = useState(null);

  // For the email modal
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");

  // Additional attachments (besides the combined PDF)
  const [emailAttachments, setEmailAttachments] = useState([]);

  // ---------------- NEW: controlling PdfEditor modal ----------------
  const [showPdfEditorModal, setShowPdfEditorModal] = useState(false);

  // Dark theme
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: {
        default: "#000000",
        paper: "#000000",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
      primary: {
        main: "#3c94fc",
      },
      secondary: {
        main: "#f50057",
      },
    },
  });

  // ============================
  // useEffects for fetching data
  // ============================
  // Fetch the LC PDF (or image, or doc, etc.) URL
  useEffect(() => {
    const fetchLcUrl = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-load-pdf?orderNumber=${encodeURIComponent(orderNumber)}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF for order #${orderNumber}`);
        }
        const data = await response.json();
        if (data && data.pdfUrl) {
          setLcUrl(data.pdfUrl);
        } else {
          console.warn("No pdfUrl returned from backend.");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (orderNumber) {
      fetchLcUrl();
    }
  }, [orderNumber]);

  // Set userId from sessionStorage
  useEffect(() => {
    setUserId(sessionStorage.getItem("storageUserId"));
  }, []);

  // Combined effect to fetch load details and invoice terms
  const companyInfo = {
    name: sessionStorage.getItem("storageCompanyName"),
    address: sessionStorage.getItem("storageAddress"),
    city: sessionStorage.getItem("storageCity"),
    state: sessionStorage.getItem("storageState"),
    zip: sessionStorage.getItem("storageZip"),
    phone: sessionStorage.getItem("storagePhone"),
    email: sessionStorage.getItem("storageCustomsEmail"),
    website: sessionStorage.getItem("storageCompanyWebsite"),
    logoBase64: sessionStorage.getItem("storageCompanyLogo"),
  };

  const billToInfo = {
    name: loadGivingCompany.companyName,
    address: loadGivingCompany.streetAddress,
    city: loadGivingCompany.city,
    state: loadGivingCompany.state,
    zip: loadGivingCompany.zip,
    phone: loadGivingCompany.phoneNumber,
    email: accountingEmail,
  };

  useEffect(() => {
    if (!orderNumber || !userId) return;

    const fetchLoadDetails = async () => {
      try {
        const response = await fetch(
          `/api/get-load-details?orderNumber=${encodeURIComponent(orderNumber)}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch load details for order #${orderNumber}`
          );
        }
        const data = await response.json();

        const parsedPickups =
          typeof data.PickupAddress === "string"
            ? JSON.parse(data.PickupAddress)
            : data.PickupAddress;
        const parsedDeliveries =
          typeof data.DeliveryAddress === "string"
            ? JSON.parse(data.DeliveryAddress)
            : data.DeliveryAddress;
        const parsedCompanyAddress =
          typeof data.CompanyAddress === "string"
            ? JSON.parse(data.CompanyAddress)
            : data.CompanyAddress;

        setPickupDate(data.PickupDate || "");
        setPickupLocation(data.PickupLocation || "");
        setDeliveryLocation(data.DeliveryLocation || "");
        setDeliveryDate(data.DeliveryDate || "");
        setRate(data.Price || 0.0);
        setCurrency(data.Currency || "");
        setDispatcherEmail(data.DispatchEmail || "");
        setAccountingEmail(data.AccountingEmail || "");
        setPickups(parsedPickups || []);
        setDeliveries(parsedDeliveries || []);
        setLoadGivingCompany({
          companyName: parsedCompanyAddress?.companyName || "",
          streetAddress: parsedCompanyAddress?.streetAddress || "",
          city: parsedCompanyAddress?.city || "",
          state: parsedCompanyAddress?.state || "",
          zip: parsedCompanyAddress?.zip || "",
          phoneNumber: parsedCompanyAddress?.phoneNumber || "",
        });
        setCustomerOrderNumber(data.CustomerOrderNumber);
      } catch (error) {
        console.error("Error fetching load details:", error);
      }
    };

    const fetchTerms = async () => {
      try {
        const response = await fetch(
          `/api/get-terms-conditions?userId=${encodeURIComponent(
            userId
          )}&companyName=${encodeURIComponent(companyInfo.name)}`
        );
        if (response.ok) {
          const data = await response.json();
          setTerms(data.Invoice || "");
        } else {
          console.error("Failed to fetch terms and conditions.");
          setTerms("");
        }
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
        setTerms("");
      }
    };

    fetchLoadDetails();
    fetchTerms();
  }, [orderNumber, userId, companyInfo.name]);

  // ==============================
  // PDF Merging Helpers
  // ==============================
  const mergePdfBuffers = async (buffers, forceLetter = false) => {
    const mergedPdf = await PDFDocument.create();
    for (const buffer of buffers) {
      const loadedPdf = await PDFDocument.load(buffer);
      const pages = loadedPdf.getPages();
      for (let i = 0; i < pages.length; i++) {
        if (forceLetter) {
          const [embeddedPage] = await mergedPdf.embedPages([pages[i]]);
          const letterWidth = 612;
          const letterHeight = 792;
          const origWidth = embeddedPage.width;
          const origHeight = embeddedPage.height;
          const xScale = letterWidth / origWidth;
          const yScale = letterHeight / origHeight;
          const scale = Math.min(xScale, yScale);
          const scaledWidth = origWidth * scale;
          const scaledHeight = origHeight * scale;
          const x = (letterWidth - scaledWidth) / 2;
          const y = (letterHeight - scaledHeight) / 2;
          const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
          newPage.drawPage(embeddedPage, {
            x,
            y,
            xScale: scale,
            yScale: scale,
          });
        } else {
          const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [i]);
          mergedPdf.addPage(copiedPage);
        }
      }
    }
    return mergedPdf.save();
  };

  const copyPagesIntoMerged = async (
    mergedPdf,
    pdfBytes,
    forceLetter = false
  ) => {
    const loadedPdf = await PDFDocument.load(pdfBytes);
    const pages = loadedPdf.getPages();
    for (let i = 0; i < pages.length; i++) {
      if (forceLetter) {
        const letterWidth = 612;
        const letterHeight = 792;
        const [embeddedPage] = await mergedPdf.embedPages([pages[i]]);
        const origWidth = embeddedPage.width;
        const origHeight = embeddedPage.height;
        const xScale = letterWidth / origWidth;
        const yScale = letterHeight / origHeight;
        const scale = Math.min(xScale, yScale);
        const scaledWidth = origWidth * scale;
        const scaledHeight = origHeight * scale;
        const x = (letterWidth - scaledWidth) / 2;
        const y = (letterHeight - scaledHeight) / 2;
        const newPage = mergedPdf.addPage([letterWidth, letterHeight]);
        newPage.drawPage(embeddedPage, { x, y, xScale: scale, yScale: scale });
      } else {
        const [copiedPage] = await mergedPdf.copyPages(loadedPdf, [i]);
        mergedPdf.addPage(copiedPage);
      }
    }
  };

  // ==============================
  // File Handling & Cropping Logic
  // ==============================
  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const pdfBuffers = [];
    const newImageFiles = [];

    for (const file of files) {
      if (file.type === "application/pdf") {
        // PDF for merging
        pdfBuffers.push(await file.arrayBuffer());
      } else {
        // Could be image or other type
        if (file.type.startsWith("image/")) {
          // Immediately process the entire image
          let processedBlob = null;
          try {
            processedBlob = await processImageFile(file);
          } catch (error) {
            console.error("Error processing image:", error);
          }

          const previewUrl = URL.createObjectURL(file);

          newImageFiles.push({
            id: Math.random().toString(36).substring(2),
            file,
            previewUrl,
            croppedBlob: null,
            processedBlob: processedBlob || null,
            isCropped: false,
            error: null,
          });
        } else {
          // Non-image, non-pdf -> skip merging, can attach in email
          console.log(`Skipping merge for file type: ${file.type}`);
        }
      }
    }

    // If there are new PDF files, merge them with any existing PDF stored in podFile.
    let existingPdfBuffers = [];
    if (podFile) {
      const existingArrayBuffer = await new Response(podFile).arrayBuffer();
      existingPdfBuffers.push(existingArrayBuffer);
    }

    const allPdfBuffers = [...existingPdfBuffers, ...pdfBuffers];
    let mergedPdfBytes = null;
    if (allPdfBuffers.length > 0) {
      mergedPdfBytes = await mergePdfBuffers(allPdfBuffers, true);
    }

    // If we have a merged PDF, store it as the new podFile
    if (mergedPdfBytes) {
      const mergedBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
      setPodFile(mergedBlob);
    }

    // Add new images to state
    setImageFiles((prev) => [...prev, ...newImageFiles]);
  };

  const handleOpenCropModal = (imgFileObj) => {
    setCropImageObj(imgFileObj);
    setOpenCropModal(true);
  };

  const handleCloseCropModal = () => {
    setOpenCropModal(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setCropImageObj(null);
  };

  // Called by react-easy-crop after each move
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleConfirmCrop = async () => {
    if (!cropImageObj || !croppedAreaPixels) return;
    try {
      // 1) get cropped blob
      const croppedBlob = await getCroppedImg(
        cropImageObj.previewUrl,
        croppedAreaPixels
      );
      // 2) Process with grayscale, auto-level, sharpen
      const processedBlob = await processImageFile(croppedBlob);

      // 3) Update the imageFile entry
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return {
              ...item,
              croppedBlob: croppedBlob,
              processedBlob: processedBlob,
              isCropped: true,
              error: null,
            };
          }
          return item;
        })
      );
    } catch (error) {
      console.error(error);
      setImageFiles((prev) =>
        prev.map((item) => {
          if (item.id === cropImageObj.id) {
            return { ...item, error: "Cropping failed." };
          }
          return item;
        })
      );
    }
    handleCloseCropModal();
  };

  // ==============================
  // T&C Editing
  // ==============================
  const handleToggleTerms = () => {
    setShowTnC((prev) => !prev);
  };

  const handleSaveTerms = async () => {
    try {
      const response = await fetch("/api/save-terms-conditions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          companyName: companyInfo.name,
          Invoice: terms,
        }),
      });
      if (response.ok) {
        alert("Terms and Conditions saved successfully.");
      } else {
        alert("Error saving Terms and Conditions.");
      }
    } catch (error) {
      console.error("Error saving terms and conditions:", error);
      alert("Error saving Terms and Conditions.");
    }
  };

  // ==============================
  // Invoice Generation
  // ==============================
  const handleCreateInvoice = () => {
    const invoiceData = {
      company: companyInfo,
      billTo: billToInfo,
      invoice: {
        number: orderNumber,
        date: new Date().toISOString().split("T")[0],
        customerOrder: customerOrderNumber,
        freightCharge: rate,
        additionalCharges: 0.0,
        currency: currency,
      },
      terms: terms,
      pickups: pickups.map((p) => ({
        address: `${p.locationName}, ${p.streetAddress}, ${p.city}, ${p.state} ${p.zip}`,
      })),
      deliveries: deliveries.map((d) => ({
        address: `${d.locationName}, ${d.streetAddress}, ${d.city}, ${d.state} ${d.zip}`,
      })),
    };

    localStorage.setItem("invoiceData", JSON.stringify(invoiceData));
    window.open("/invoice.html", "_blank");
  };

  // ==============================
  // Combine PDFs (invoice + LC + POD images)
  // ==============================
  const handleCombinePDFs = async () => {
    try {
      const mergedPdf = await PDFDocument.create();

      // 1) If invoicePdfUrl is available
      if (invoicePdfUrl) {
        const invoiceBytes = await fetch(invoicePdfUrl).then((res) =>
          res.arrayBuffer()
        );
        await copyPagesIntoMerged(mergedPdf, invoiceBytes, false);
      }

      // 2) Handle the Load Confirmation (lcUrl) if present
      if (lcUrl && lcUrl.trim() !== "") {
        // Check file extension or fallback to content-type
        const extension = lcUrl.split(".").pop().toLowerCase();
        let isWordFile = ["doc", "docx"].includes(extension);
        let isPdfFile = extension === "pdf";
        let isImageFile = ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          extension
        );

        // If the extension is not in the URL, we might need to check content-type:
        // But we'll keep it simple with extension checks for now.
        if (isWordFile) {
          alert(
            "Warning: The load confirmation is a Word document. It will NOT be combined."
          );
        } else if (isPdfFile) {
          // Merge as PDF
          const lcBytes = await fetch(lcUrl).then((res) => res.arrayBuffer());
          await copyPagesIntoMerged(mergedPdf, lcBytes, true);
        } else if (isImageFile) {
          // Convert to PDF
          const response = await fetch(lcUrl);
          const imageBlob = await response.blob();
          // Create new PDF with the single image
          const tempPdfDoc = await PDFDocument.create();
          const buffer = await imageBlob.arrayBuffer();

          // Attempt to identify if PNG vs JPG for embedding
          const contentType = response.headers.get("content-type") || "";
          let embeddedImage;
          if (contentType.includes("png") || extension === "png") {
            embeddedImage = await tempPdfDoc.embedPng(buffer);
          } else {
            embeddedImage = await tempPdfDoc.embedJpg(buffer);
          }

          const { width, height } = embeddedImage.scale(1);
          // Create a page the same size as the (processed) image
          const page = tempPdfDoc.addPage([width, height]);
          page.drawImage(embeddedImage, {
            x: 0,
            y: 0,
            width,
            height,
          });

          const tempPdfBytes = await tempPdfDoc.save();
          await copyPagesIntoMerged(mergedPdf, tempPdfBytes, true);
        } else {
          alert(
            "Warning: The load confirmation file type is not supported. It will NOT be combined."
          );
        }
      }

      // 3) If a merged POD PDF is stored
      if (podFile) {
        const podBytes = await new Response(podFile).arrayBuffer();
        await copyPagesIntoMerged(mergedPdf, podBytes, true);
      }

      // 4) Image files that have been processed (whether cropped or not)
      let imagePdfBuffers = [];
      if (imageFiles && imageFiles.length > 0) {
        const imagesToConvert = imageFiles.filter((f) => f.processedBlob);
        if (imagesToConvert.length > 0) {
          const tempPdfDoc = await PDFDocument.create();
          for (const imgObj of imagesToConvert) {
            const buffer = await imgObj.processedBlob.arrayBuffer();
            let embeddedImage;
            // Rough check for png vs jpg
            if (imgObj.file.type.includes("png")) {
              embeddedImage = await tempPdfDoc.embedPng(buffer);
            } else {
              embeddedImage = await tempPdfDoc.embedJpg(buffer);
            }
            const { width, height } = embeddedImage.scale(1);
            // Create a page the same size as the (processed) image
            const page = tempPdfDoc.addPage([width, height]);
            page.drawImage(embeddedImage, {
              x: 0,
              y: 0,
              width,
              height,
            });
          }
          const tempPdfBytes = await tempPdfDoc.save();
          imagePdfBuffers.push(tempPdfBytes);
        }
      }

      // Merge any PDF made from images
      if (imagePdfBuffers.length > 0) {
        for (let pdfBuffer of imagePdfBuffers) {
          await copyPagesIntoMerged(mergedPdf, pdfBuffer, true);
        }
      }

      // 5) Finalize & store the combined PDF
      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });

      // We store the combined PDF so user can attach it in the email
      setCombinedPdfFile(blob);

      // ---------- Open the PdfEditor after combining ----------
      setShowPdfEditorModal(true);
    } catch (error) {
      console.error("Error combining PDFs:", error);
      alert("There was an error combining the PDFs. Please try again.");
    }
  };

  // Listen for message from invoice.html (when the PDF is generated)
  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data && event.data.type === "INVOICE_PDF") {
        setInvoicePdfUrl(event.data.pdfDataUrl);
      }
    };
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, []);

  // React Quill modules
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  // =======================
  // Email Modal Logic
  // =======================
  const handleOpenEmailModal = () => {
    // Default subject & body
    setEmailSubject(`Invoice POD ${customerOrderNumber || ""}`);
    setEmailBody(
      `Hi,\nPlease find attached the invoice and POD for order # ${
        customerOrderNumber || ""
      }.\nPlease confirm receipt.\n\nThanks.`
    );
    setEmailTo(accountingEmail || "");
    setEmailCc(dispatcherEmail || "");
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };

  const handleEmailAttachmentChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setEmailAttachments((prev) => [...prev, ...Array.from(files)]);
    }
  };

  const handleRemoveAttachment = (index) => {
    setEmailAttachments((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleSendEmail = () => {
    // Implement actual email sending logic if needed
    const attachmentsList = [...emailAttachments];
    console.log("Sending Email with the following data:");
    console.log("Subject:", emailSubject);
    console.log("To:", emailTo);
    console.log("CC:", emailCc);
    console.log("Body:", emailBody);
    console.log("Combined PDF file:", combinedPdfFile);
    console.log("Additional attachments:", attachmentsList);

    alert("Email send triggered. Check console for details.");
    handleCloseEmailModal();
  };

  // =======================
  // AI Enhancement for Subject & Body
  // =======================
  const handleEnhanceEmail = async () => {
    try {
      const response = await fetch("/api/enhance-subject-body", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subject: emailSubject,
          body: emailBody,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to enhance subject/body");
      }

      const data = await response.json();

      // data.message is a string containing JSON. We need to parse it again.
      const parsedMessage = JSON.parse(data.message);

      if (parsedMessage.subject) {
        setEmailSubject(parsedMessage.subject);
        console.log("Enhanced Subject:", parsedMessage.subject);
      }
      if (parsedMessage.body) {
        setEmailBody(parsedMessage.body);
        console.log("Enhanced Body:", parsedMessage.body);
      }
    } catch (error) {
      console.error("Error in AI enhancement:", error);
      alert("Error enhancing email content. Please try again.");
    }
  };

  // =========== HANDLERS for PdfEditor Modal ============
  const handlePdfEditorClose = () => {
    setShowPdfEditorModal(false);
  };

  const handlePdfEditorSave = (updatedBlob) => {
    // Once user saves from PdfEditor, we store the updated PDF
    setCombinedPdfFile(updatedBlob);
  };

  // -------------- NEW: Open Combined PDF in new tab --------------
  const handleViewCombinedPdf = () => {
    if (!combinedPdfFile) {
      alert("No combined PDF is available to view.");
      return;
    }
    const url = URL.createObjectURL(combinedPdfFile);
    window.open(url, "_blank");
  };

  // -------------- NEW: Button handler to edit the order --------------
  const handleEditOrder = () => {
    sessionStorage.setItem("selectedLoadNumber", orderNumber);
    navigate("/edit-order", {
      state: { from: "invoicing" },
    });
  };

  // If loading, show spinner
  if (isLoading) {
    return (
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000000",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="create-invoice-container">
        <SideNavBar />
        <div className="create-invoice-window">
          <div style={{ flex: 1 }}>
            <Toolbar style={{ minHeight: "83px" }} />
            <div className="create-invoice-body">
              <Box
                component={Paper}
                sx={{ m: 2, p: 2, backgroundColor: "#000000" }}
              >
                <Typography variant="h4" gutterBottom>
                  Invoicing for Order # {orderNumber}
                </Typography>

                {/* Load Details */}
                <Box sx={{ my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Load Details:
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickup Date:</strong> {pickupDate}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickup Location:</strong> {pickupLocation}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Delivery Location:</strong> {deliveryLocation}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Delivery Date:</strong> {deliveryDate}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Rate:</strong> {rate} {currency}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Dispatcher Email:</strong> {dispatcherEmail}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Accounting Email:</strong> {accountingEmail}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pickups:</strong>{" "}
                    {Array.isArray(pickups)
                      ? pickups.map((pickup, idx) => (
                          <span key={idx}>
                            {pickup.locationName}, {pickup.streetAddress},{" "}
                            {pickup.city}, {pickup.state} {pickup.zip}
                            {idx < pickups.length - 1 ? " | " : ""}
                          </span>
                        ))
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Deliveries:</strong>{" "}
                    {Array.isArray(deliveries)
                      ? deliveries.map((delivery, idx) => (
                          <span key={idx}>
                            {delivery.locationName}, {delivery.streetAddress},{" "}
                            {delivery.city}, {delivery.state} {delivery.zip}
                            {idx < deliveries.length - 1 ? " | " : ""}
                          </span>
                        ))
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Load Giving Company:</strong>{" "}
                    {loadGivingCompany.companyName} -{" "}
                    {loadGivingCompany.streetAddress}, {loadGivingCompany.city},{" "}
                    {loadGivingCompany.state} {loadGivingCompany.zip} (Phone:{" "}
                    {loadGivingCompany.phoneNumber})
                  </Typography>
                </Box>

                {/* Display existing PDF or file link */}
                {lcUrl && lcUrl.trim() !== "" && (
                  <Box sx={{ my: 2 }}>
                    <Typography variant="body1">
                      Existing Order Confirmation File:
                    </Typography>
                    <a
                      href={lcUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#3c94fc" }}
                    >
                      View or Download
                    </a>
                  </Box>
                )}

                {/* POD Upload */}
                <Typography variant="body1" gutterBottom>
                  Upload your Proof of Delivery (POD) file(s) here.
                </Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Button variant="contained" component="label" color="primary">
                    Select File(s)
                    <input
                      type="file"
                      hidden
                      accept="application/pdf, image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                  </Button>
                </Box>

                {podFile && (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Merged PDF (from previously uploaded PDFs) is ready.
                  </Typography>
                )}

                {/* Show image files that the user uploaded and let them crop. */}
                {imageFiles.length > 0 && (
                  <Box sx={{ border: "1px solid #aaa", p: 2, my: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      POD Images (Crop optional)
                    </Typography>
                    {imageFiles.map((img) => (
                      <Box
                        key={img.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <img
                          src={img.previewUrl}
                          alt="preview"
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: "cover",
                            marginRight: "1rem",
                            border: "1px solid #666",
                          }}
                        />
                        {img.isCropped ? (
                          <Typography variant="body2" color="green">
                            Cropped & Processed
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ mr: 2, color: "#ccc" }}
                          >
                            Automatically Processed
                          </Typography>
                        )}
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => handleOpenCropModal(img)}
                        >
                          Crop (Optional)
                        </Button>

                        {img.error && (
                          <Typography
                            variant="body2"
                            color="red"
                            sx={{ ml: 2 }}
                          >
                            {img.error}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}

                {/* Crop modal */}
                <Modal
                  open={openCropModal}
                  onClose={handleCloseCropModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "90vw",
                      height: "80vh",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      outline: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">
                        Crop your image (optional)
                      </Typography>
                      <IconButton onClick={handleCloseCropModal}>
                        <CloseIcon />
                      </IconButton>
                    </Box>

                    {/* Cropping area */}
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "60%",
                        background: "#333",
                        mb: 2,
                      }}
                    >
                      {cropImageObj && (
                        <CropEasy
                          image={cropImageObj.previewUrl}
                          crop={crop}
                          zoom={zoom}
                          aspect={null}
                          cropShape="rect"
                          showGrid={true}
                          restrictPosition={false}
                          cropSize={cropSize}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                        />
                      )}
                    </Box>

                    {/* Sliders to resize bounding box and zoom */}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      ** Adjust Crop Box Size **
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Box sx={{ width: "40%" }}>
                        <Typography>Width: {cropSize.width}px</Typography>
                        <Slider
                          value={cropSize.width}
                          min={50}
                          max={1200}
                          step={10}
                          onChange={(e, newVal) =>
                            setCropSize((prev) => ({ ...prev, width: newVal }))
                          }
                        />
                      </Box>
                      <Box sx={{ width: "40%" }}>
                        <Typography>Height: {cropSize.height}px</Typography>
                        <Slider
                          value={cropSize.height}
                          min={50}
                          max={1200}
                          step={10}
                          onChange={(e, newVal) =>
                            setCropSize((prev) => ({
                              ...prev,
                              height: newVal,
                            }))
                          }
                        />
                      </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <Typography>Zoom: {zoom.toFixed(2)}</Typography>
                      <Slider
                        value={zoom}
                        min={1}
                        max={5}
                        step={0.1}
                        onChange={(e, zoomValue) => setZoom(zoomValue)}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleConfirmCrop}
                      >
                        Confirm Crop
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {/* Terms and Conditions Section */}
                <Box sx={{ mt: 4, mb: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggleTerms}
                  >
                    {showTnC
                      ? "Hide Terms and Conditions"
                      : "Edit Terms and Conditions"}
                  </Button>
                  {showTnC ? (
                    <Box sx={{ mt: 2 }}>
                      <ReactQuill
                        theme="snow"
                        value={terms}
                        onChange={setTerms}
                        modules={quillModules}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      />
                      <Box sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleSaveTerms}
                        >
                          Save Terms and Conditions
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{ mt: 2, whiteSpace: "pre-wrap", color: "#ffffff" }}
                    >
                      <Typography variant="body1">
                        <span
                          dangerouslySetInnerHTML={{ __html: terms }}
                        ></span>
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* Create Invoice, Combine PDFs, Edit Order, and Email Buttons */}
                <Box sx={{ mt: 4, mb: 2, display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCreateInvoice}
                  >
                    Create Invoice
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCombinePDFs}
                  >
                    Combine PDFs
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenEmailModal}
                    disabled={!combinedPdfFile}
                  >
                    Send Email
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleViewCombinedPdf}
                    disabled={!combinedPdfFile}
                  >
                    View Combined PDF
                  </Button>
                  {/* ----- NEW Button to Edit Order ----- */}
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleEditOrder}
                  >
                    Edit Order
                  </Button>
                </Box>

                {/* EMAIL Modal */}
                <Modal
                  open={openEmailModal}
                  onClose={handleCloseEmailModal}
                  aria-labelledby="modal-email-title"
                  aria-describedby="modal-email-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "600px",
                      maxWidth: "90vw",
                      bgcolor: "#333",
                      color: "#fff",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">Send Email</Typography>
                      <IconButton onClick={handleCloseEmailModal}>
                        <CloseIcon style={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                    <Box
                      component="form"
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <TextField
                        label="Subject"
                        variant="filled"
                        fullWidth
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="To"
                        variant="filled"
                        fullWidth
                        value={emailTo}
                        onChange={(e) => setEmailTo(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="CC"
                        variant="filled"
                        fullWidth
                        value={emailCc}
                        onChange={(e) => setEmailCc(e.target.value)}
                        InputProps={{ style: { color: "#fff" } }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />
                      <TextField
                        label="Body"
                        variant="filled"
                        multiline
                        rows={5}
                        fullWidth
                        value={emailBody}
                        onChange={(e) => setEmailBody(e.target.value)}
                        InputProps={{
                          style: { color: "#fff" },
                          endAdornment: (
                            <IconButton onClick={handleEnhanceEmail}>
                              <SmartToyIcon sx={{ color: "#fff" }} />
                            </IconButton>
                          ),
                        }}
                        InputLabelProps={{ style: { color: "#aaa" } }}
                        sx={{ backgroundColor: "#222" }}
                      />

                      {/* Attachments */}
                      <Box>
                        <InputLabel sx={{ mb: 1, color: "#aaa" }}>
                          Additional Attachments
                        </InputLabel>
                        <Button variant="contained" component="label">
                          Add Files
                          <input
                            type="file"
                            hidden
                            multiple
                            onChange={handleEmailAttachmentChange}
                          />
                        </Button>
                        {emailAttachments.length > 0 && (
                          <List
                            dense
                            sx={{ mt: 2, maxHeight: 150, overflowY: "auto" }}
                          >
                            {emailAttachments.map((file, idx) => (
                              <ListItem key={idx}>
                                <ListItemText
                                  primary={file.name}
                                  secondary={`${(file.size / 1024).toFixed(
                                    2
                                  )} KB`}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleRemoveAttachment(idx)}
                                  >
                                    <DeleteIcon sx={{ color: "#fff" }} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Box>

                      {/* The combined PDF file is automatically attached (not removable) */}
                      {combinedPdfFile && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                          <strong>Auto-attached:</strong> combined.pdf
                        </Typography>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleSendEmail}
                        >
                          Send
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>

                {/* PDF Editor Modal */}
                {combinedPdfFile && (
                  <Modal
                    open={showPdfEditorModal}
                    onClose={handlePdfEditorClose}
                    aria-labelledby="pdf-editor-modal"
                    aria-describedby="pdf-editor-modal-description"
                  >
                    <Box
                      sx={{
                        width: "95vw",
                        height: "95vh",
                        margin: "auto",
                        mt: 2,
                        backgroundColor: "#000",
                        position: "relative",
                        overflow: "auto",
                      }}
                    >
                      <PdfEditor
                        // Provide the newly combined PDF blob
                        combinedPdfBlob={combinedPdfFile}
                        // Once the user clicks "Save" in the PdfEditor
                        onSave={handlePdfEditorSave}
                        onClose={handlePdfEditorClose}
                        showSideNavBar={false}
                        paddingLeft="0%"
                        paddingRight="0%"
                        prefix="MyInvoice"
                        number={orderNumber || ""}
                        matchedBorder="Final"
                      />
                    </Box>
                  </Modal>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Invoicing;
