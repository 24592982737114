// Orders.js
import React, { useState, useEffect, useRef } from "react";
import SideNavBar from "./SideNavBar";
import LoadingIndicator from "./LoadingIndicator";
import ConfirmationModal from "./ConfirmationModal";
import OrderDetails from "./OrderDetails";
import "./Chat.css";
import { Toolbar } from "@mui/material";
import { createClient } from "@supabase/supabase-js";

function Orders() {
  const MAX_MESSAGES = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [input, setInput] = useState("");

  // Order details
  const [orderNumber, setOrderNumber] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [rate, setRate] = useState(0.0);
  const [currency, setCurrency] = useState("");
  const [priceGiven, setPriceGiven] = useState(0.0);
  const [tripType, setTripType] = useState("");
  const [equipment, setEquipment] = useState("");
  const [status, setStatus] = useState("");

  // When user clicks "Add New Order" manually
  const [showManualOrder, setShowManualOrder] = useState(false);

  // **New: Show order details form on any backend response**
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  // Uploaded file and other states
  const [uploadedFileBlob, setUploadedFileBlob] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const chatBodyRef = useRef(null);
  const inputRef = useRef(null);
  const [storageUserId, setStorageUserId] = useState("");

  // Additional data
  const [accountingEmail, setAccountingEmail] = useState("");
  const [dispatcherEmail, setDispatcherEmail] = useState("");
  const [pickups, setPickups] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [loadGivingCompany, setLoadGivingCompany] = useState({
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
  });

  // Supabase setup
  const supabaseUrl = "https://bkyisyxrbccchnmvksvj.supabase.co";
  const supabaseKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJreWlzeXhyYmNjY2hubXZrc3ZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQzMDMyNzcsImV4cCI6MjA0OTg3OTI3N30.qqnqqNWQK27zUzmuqydQA5lmS3-x_Yd41mqykrUkBcc";
  const supabase = createClient(supabaseUrl, supabaseKey);

  // Ref for the hidden file input
  const fileInputRef = useRef(null);

  // Utility function to convert a string to title case
  const toTitleCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // On mount, generate a session ID if it doesn't exist
  useEffect(() => {
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  // Retrieve user ID from sessionStorage if present
  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    if (storedUserId) {
      setStorageUserId(storedUserId);
      console.log("User ID:", storedUserId);
    }
  }, []);

  // Always scroll chat to bottom on new message or loading state
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory, isLoading]);

  // Add system (AI) message to chat
  const addSystemMessage = (content) => {
    const newMessage = {
      id: generateUniqueId(),
      ...content, // e.g. { text: "...", type: "pdf" }
      isUser: false,
    };
    setChatHistory((prevHistory) =>
      [...prevHistory, newMessage].slice(-MAX_MESSAGES)
    );
  };

  // Modal open/close
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Confirm "New Chat" - resets everything
  const confirmNewChat = async () => {
    resetAllFields();
    await handleRequestCompleted(false);
    setChatHistory([]);
    addSystemMessage({ text: "How can I assist you with Orders Addition?" });
    closeModal();
  };

  // A quick "Request Completed" message sending
  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";
    setIsLoading(true);

    if (displayMessage) {
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, userMessage].slice(-MAX_MESSAGES)
      );
    }

    try {
      const response = await callAzureFunction(textToSend);
      const answer = response.message;

      if (typeof answer === "object" && answer !== null) {
        // Usually handle structured data, but not used here
      } else {
        const aiMessage = {
          id: generateUniqueId(),
          text: answer,
          isUser: false,
        };
        setChatHistory((prevHistory) =>
          [...prevHistory, aiMessage].slice(-MAX_MESSAGES)
        );
      }
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, errorMessage].slice(-MAX_MESSAGES)
      );
    } finally {
      setIsLoading(false);
    }
  };

  // handleSend in Orders.js
  const handleSend = async () => {
    const textToSend = input.trim();
    if (!textToSend) return;

    // Add user's message to chat
    setChatHistory((prev) => [
      ...prev,
      { id: generateUniqueId(), text: textToSend, isUser: true },
    ]);

    setInput("");
    setIsLoading(true);

    try {
      // Call text-based route
      const response = await callAzureFunction(textToSend);
      // Log it fully to see its shape
      console.log(
        "Text route raw response:",
        JSON.stringify(response, null, 2)
      );

      // If the response structure is exactly the same as file upload, do:
      const data = response; // or parse it if needed

      // We got a response, so let's show the order details form
      setShowOrderDetails(true);

      // Populate each field from data.load_reference, data.load_giving_company, etc.
      if (data.load_reference) {
        setOrderNumber(data.load_reference.order_number || "");
        setPickupDate(data.load_reference.pickup_date || "");
        setPickupLocation(data.load_reference.pickup_location || "");
        setDeliveryLocation(data.load_reference.delivery_location || "");
        setDeliveryDate(data.load_reference.delivery_date || "");
        setRate(data.load_reference.rate || 0.0);
        setCurrency(
          data.load_reference.currency
            ? data.load_reference.currency.toUpperCase()
            : ""
        );
      }

      if (data.accounting_dispatch_email) {
        setAccountingEmail(
          (
            data.accounting_dispatch_email.accountingEmail || "NOT FOUND"
          ).toLowerCase()
        );
        setDispatcherEmail(
          (
            data.accounting_dispatch_email.dispatcherEmail || "NOT FOUND"
          ).toLowerCase()
        );
      }

      if (data.pickup_delivery_location) {
        const { pickups = [], deliveries = [] } = data.pickup_delivery_location;
        setPickups(pickups);
        setDeliveries(deliveries);
      }

      if (data.load_giving_company) {
        setLoadGivingCompany({
          companyName: data.load_giving_company.companyName || "",
          streetAddress: data.load_giving_company.streetAddress || "",
          city: data.load_giving_company.city || "",
          state: data.load_giving_company.state || "",
          zip: data.load_giving_company.zip || "",
          phoneNumber: data.load_giving_company.phoneNumber || "",
        });
      }
    } catch (err) {
      console.error("Error fetching response from API:", err);
      setChatHistory((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          text: "Error fetching response.",
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Clear chat history and reset order details whenever a new file is uploaded
    resetAllFields();

    let route = "";
    const fileType = file.type;

    if (fileType === "application/pdf") {
      route = "/api/load-info-pdf-manager";
    } else if (fileType.startsWith("image/")) {
      route = "/api/load-info-image-manager";
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      route = "/api/load-info-word-manager";
    } else {
      alert(
        "Unsupported file type. Please upload PDF, Image, Excel, or Word files."
      );
      event.target.value = "";
      return;
    }

    // Store file in state
    const newBlob = new Blob([file], { type: fileType });
    setUploadedFileBlob(newBlob);
    setUploadedFileName(file.name);

    // Create FormData
    const formData = new FormData();
    formData.append("file", newBlob);
    formData.append("session_id", sessionStorage.getItem("session_id"));

    // Add file as a user message in chat for preview
    const reader = new FileReader();
    reader.onload = () => {
      const fileDataUrl = reader.result;
      const fileMessage = {
        id: generateUniqueId(),
        fileName: file.name,
        fileUrl: fileDataUrl,
        isUser: true,
        type: "file",
      };
      setChatHistory((prevHistory) =>
        [...prevHistory, fileMessage].slice(-MAX_MESSAGES)
      );
    };
    reader.readAsDataURL(file);

    // Send to the chosen route
    setIsLoading(true);
    try {
      const response = await fetch(route, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }

      const data = await response.json();

      // We received a response, so show the order details form
      setShowOrderDetails(true);

      // If the server returns a structure containing load info, parse it
      if (data.accounting_dispatch_email) {
        setAccountingEmail(
          (
            data.accounting_dispatch_email.accountingEmail || "NOT FOUND"
          ).toLowerCase()
        );
        setDispatcherEmail(
          (
            data.accounting_dispatch_email.dispatcherEmail || "NOT FOUND"
          ).toLowerCase()
        );
      }

      if (data.pickup_delivery_location) {
        // Format pickups
        const transformedPickups = (
          data.pickup_delivery_location.pickups || []
        ).map((pickup) => ({
          ...pickup,
          locationName: toTitleCase(pickup.locationName || ""),
          streetAddress: toTitleCase(pickup.streetAddress || ""),
          city: toTitleCase(pickup.city || ""),
          state: (pickup.state || "").toUpperCase(),
          zip: (pickup.zip || "").toUpperCase(),
        }));
        setPickups(transformedPickups);

        // Format deliveries
        const transformedDeliveries = (
          data.pickup_delivery_location.deliveries || []
        ).map((delivery) => ({
          ...delivery,
          locationName: toTitleCase(delivery.locationName || ""),
          streetAddress: toTitleCase(delivery.streetAddress || ""),
          city: toTitleCase(delivery.city || ""),
          state: (delivery.state || "").toUpperCase(),
          zip: (delivery.zip || "").toUpperCase(),
        }));
        setDeliveries(transformedDeliveries);
      }

      // Format loadGivingCompany
      if (data.load_giving_company) {
        setLoadGivingCompany({
          companyName: toTitleCase(data.load_giving_company.companyName || ""),
          streetAddress: toTitleCase(
            data.load_giving_company.streetAddress || ""
          ),
          city: toTitleCase(data.load_giving_company.city || ""),
          state: (data.load_giving_company.state || "").toUpperCase(),
          zip: (data.load_giving_company.zip || "").toUpperCase(),
          phoneNumber: data.load_giving_company.phoneNumber || "",
        });
      }

      // Format load reference
      if (data.load_reference) {
        setOrderNumber(data.load_reference.order_number || "");
        setPickupDate(data.load_reference.pickup_date || "");
        setPickupLocation(
          (data.load_reference.pickup_location || "").toUpperCase()
        );
        setDeliveryLocation(
          (data.load_reference.delivery_location || "").toUpperCase()
        );
        setDeliveryDate(data.load_reference.delivery_date || "");
        setRate(data.load_reference.rate || 0.0);
        setCurrency(
          data.load_reference.currency
            ? data.load_reference.currency.toUpperCase()
            : ""
        );
      }

      addSystemMessage({ text: "File processed successfully." });
    } catch (error) {
      console.error("Error uploading file:", error);
      addSystemMessage({ text: `Error uploading file: ${error.message}` });
    } finally {
      setIsLoading(false);
      // Clear the file input so the same file can be re-chosen if needed
      event.target.value = "";
    }
  };

  // Call the text-based Azure Function
  const callAzureFunction = async (inputText) => {
    const apiUrl = "/api/load-info-text";

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText,
        session_id: sessionStorage.getItem("session_id"),
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  // Dynamically adjust input height
  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  useEffect(() => {
    adjustInputHeight();
  }, [input]);

  // Generate unique ID
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  // Save order details to DB and Supabase storage
  const saveOrderDetails = async () => {
    try {
      let fileUrl = "";

      // Only attempt upload if a file was actually provided
      if (uploadedFileBlob) {
        // Determine the extension from the uploaded file name
        let fileExtension = "pdf";
        if (uploadedFileName && uploadedFileName.includes(".")) {
          fileExtension = uploadedFileName.split(".").pop().toLowerCase();
        }

        const storageFileName = `${loadGivingCompany.companyName}-${orderNumber}-${rate}.${fileExtension}`;

        // Upload to 'pdf-storage' bucket in 'MG Transport LC' folder
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("pdf-storage")
          .upload(`MG Transport LC/${storageFileName}`, uploadedFileBlob, {
            upsert: true,
          });

        if (uploadError || uploadData == null) {
          throw new Error(
            `Error uploading file to Supabase: ${uploadError.message}`
          );
        }

        // Retrieve public URL
        const { data: publicURLData } = supabase.storage
          .from("pdf-storage")
          .getPublicUrl(`MG Transport LC/${storageFileName}`);
        fileUrl = publicURLData.publicUrl;
      }

      // Convert pickups/deliveries to JSONB
      const formattedPickups = pickups.map((pickup) => ({
        locationName: pickup.locationName || "",
        streetAddress: pickup.streetAddress || "",
        city: pickup.city || "",
        state: pickup.state || "",
        zip: pickup.zip || "",
      }));

      const formattedDeliveries = deliveries.map((delivery) => ({
        locationName: delivery.locationName || "",
        streetAddress: delivery.streetAddress || "",
        city: delivery.city || "",
        state: delivery.state || "",
        zip: delivery.zip || "",
      }));

      const formattedCompanyAddress = {
        companyName: loadGivingCompany.companyName || "",
        streetAddress: loadGivingCompany.streetAddress || "",
        city: loadGivingCompany.city || "",
        state: loadGivingCompany.state || "",
        zip: loadGivingCompany.zip || "",
        phoneNumber: loadGivingCompany.phoneNumber || "",
      };

      // Prep data for API
      const detailsWithBlobUrl = {
        order_number: orderNumber,
        pickup_date: pickupDate,
        pickup_location: pickupLocation,
        delivery_location: deliveryLocation,
        delivery_date: deliveryDate,
        rate: rate,
        currency: currency,
        company_name: loadGivingCompany.companyName,
        price_given: priceGiven,
        trip_type: tripType,
        equipment: equipment,
        status: status,
        pdfUrl: fileUrl, // If no file was uploaded, this remains an empty string
        accountingEmail: accountingEmail,
        dispatcherEmail: dispatcherEmail,
        pickups: formattedPickups,
        deliveries: formattedDeliveries,
        companyAddress: formattedCompanyAddress,
        userId: storageUserId,
      };

      const response = await fetch("/api/add-load", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detailsWithBlobUrl),
      });

      if (!response.ok) {
        throw new Error("Failed to save order details");
      }

      const data = await response.json();
      console.log("Order details saved:", data);
      alert("Order details saved successfully.");

      // Reset form
      resetAllFields();
      setChatHistory([]);
      setShowManualOrder(false); // Hide manual order form after save
      setShowOrderDetails(false); // Hide order details on successful save
    } catch (error) {
      console.error("Error saving order details:", error);
      alert("Error saving order details.");
    }
  };

  // Reset all fields
  const resetAllFields = () => {
    setOrderNumber("");
    setPickupDate("");
    setPickupLocation("");
    setDeliveryLocation("");
    setDeliveryDate("");
    setRate(0.0);
    setCurrency("");
    setPriceGiven(0.0);
    setTripType("");
    setEquipment("");
    setStatus("");
    setUploadedFileBlob(null);
    setUploadedFileName("");
    setAccountingEmail("");
    setDispatcherEmail("");
    setPickups([]);
    setDeliveries([]);
    setLoadGivingCompany({
      companyName: "",
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      phoneNumber: "",
    });
    setShowOrderDetails(false);
  };

  // Generate session ID
  const generateSessionId = () => {
    return crypto.randomUUID();
  };

  // Handle file button click
  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle manually adding a new order
  const handleAddManualOrder = () => {
    resetAllFields();
    setShowManualOrder(true);
  };

  return (
    <div className="chat-container">
      <SideNavBar handleNewChat={openModal} />

      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option="Email Generation"
      />

      <div className="chat-window">
        <Toolbar sx={{ minHeight: "84px" }} />
        <div className="chat-body" ref={chatBodyRef}>
          {/* A button to add a new order manually */}
          <div style={{ margin: "10px 0" }}>
            <button onClick={handleAddManualOrder}>Add New Order</button>
          </div>

          {chatHistory.map((message) => (
            <div
              key={message.id}
              className={`chat-message ${
                message.isUser ? "user-message" : "system-message"
              }`}
            >
              {message.type === "file" ? (
                <div className="pdf-message">
                  📄 <strong>{message.fileName}</strong>
                  {message.fileUrl && (
                    <a
                      href={message.fileUrl}
                      download={message.fileName}
                      className="download-link"
                      style={{
                        marginTop: "10px",
                        color: "white",
                        display: "block",
                      }}
                    >
                      📥 Download
                    </a>
                  )}
                </div>
              ) : (
                message.text
              )}
            </div>
          ))}
          {isLoading && <LoadingIndicator />}

          {/*
            Render OrderDetails if:
            1) The user clicked "Add New Order" (showManualOrder === true)
               OR
            2) We have any response from the backend (showOrderDetails === true).
          */}
          {(showManualOrder || showOrderDetails) && (
            <OrderDetails
              orderNumber={orderNumber}
              setOrderNumber={setOrderNumber}
              pickupDate={pickupDate}
              setPickupDate={setPickupDate}
              pickupLocation={pickupLocation}
              setPickupLocation={setPickupLocation}
              deliveryLocation={deliveryLocation}
              setDeliveryLocation={setDeliveryLocation}
              deliveryDate={deliveryDate}
              setDeliveryDate={setDeliveryDate}
              rate={rate}
              setRate={setRate}
              currency={currency}
              setCurrency={setCurrency}
              priceGiven={priceGiven}
              setPriceGiven={setPriceGiven}
              tripType={tripType}
              setTripType={setTripType}
              equipment={equipment}
              setEquipment={setEquipment}
              status={status}
              setStatus={setStatus}
              accountingEmail={accountingEmail}
              setAccountingEmail={setAccountingEmail}
              dispatcherEmail={dispatcherEmail}
              setDispatcherEmail={setDispatcherEmail}
              pickups={pickups}
              setPickups={setPickups}
              deliveries={deliveries}
              setDeliveries={setDeliveries}
              loadGivingCompany={loadGivingCompany}
              setLoadGivingCompany={setLoadGivingCompany}
              onSave={saveOrderDetails}
            />
          )}
        </div>

        <div className="chat-footer">
          <button
            style={{ border: "none", marginRight: "10px" }}
            className="file-upload-btn"
            title="Upload Files"
            onClick={handleFileButtonClick}
          >
            📌
          </button>
          <input
            type="file"
            accept="
              application/pdf,
              image/*,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document
            "
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          <textarea
            ref={inputRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustInputHeight();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="Type a message..."
            className="input-field"
          />

          <button
            className="send-btn"
            title="Send Message"
            onClick={handleSend}
            aria-label="Send Message"
            disabled={isLoading}
          >
            ➤
          </button>
        </div>
      </div>
    </div>
  );
}

export default Orders;
