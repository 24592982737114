import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Box,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import logo from "./logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { InteractionStatus } from "@azure/msal-browser";

function Navbar({ instance, b2cPolicies, inProgress }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [customsAnchorEl, setCustomsAnchorEl] = useState(null);
  const [accountingAnchorEl, setAccountingAnchorEl] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const logoUrl = sessionStorage.getItem("storageCompanyLogo");
    if (logoUrl) {
      setCompanyLogoUrl(logoUrl);
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const openProfileModal = () => setIsProfileModalOpen(true);
  const closeProfileModal = () => setIsProfileModalOpen(false);

  const handleProfileEdit = () => {
    closeProfileModal();
    if (
      inProgress === InteractionStatus.None &&
      b2cPolicies &&
      b2cPolicies.authorities &&
      b2cPolicies.authorities.editProfile
    ) {
      instance
        .acquireTokenRedirect(b2cPolicies.authorities.editProfile)
        .catch(console.error);
    } else {
      console.error("b2cPolicies or authorities not properly defined!");
    }
  };

  const handleMsAuthSetup = () => {
    closeProfileModal();
    navigate("/ms-auth-setup");
  };

  const handleLogout = () => {
    closeProfileModal();
    instance.logoutRedirect();
  };

  const handleMenuOpen = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "/default-user-icon.png";
  };

  const drawerList = () => (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={(e) => handleMenuOpen(e, setCustomsAnchorEl)}>
          <ListItemText primary="Customs" />
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={customsAnchorEl}
        open={Boolean(customsAnchorEl)}
        onClose={() => handleMenuClose(setCustomsAnchorEl)}
      >
        {["Home", "Broker List", "PAPS", "PARS"].map((text) => (
          <MenuItem
            key={text}
            onClick={() => {
              handleMenuClose(setCustomsAnchorEl);
              setDrawerOpen(false);
            }}
            component={Link}
            to={`/${text.toLowerCase().replace(" ", "-")}`}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
      <ListItem disablePadding>
        <ListItemButton
          onClick={(e) => handleMenuOpen(e, setAccountingAnchorEl)}
        >
          <ListItemText primary="Accounting" />
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={accountingAnchorEl}
        open={Boolean(accountingAnchorEl)}
        onClose={() => handleMenuClose(setAccountingAnchorEl)}
      >
        {["Pod", "Pod History"].map((text) => (
          <MenuItem
            key={text}
            onClick={() => {
              handleMenuClose(setAccountingAnchorEl);
              setDrawerOpen(false);
            }}
            component={Link}
            to={`/${text.toLowerCase().replace(" ", "-")}`}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/edit-pdf"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemText primary="Edit PDF" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/mainPage"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemText primary="Main Page" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/chat"
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemText primary="Chat" />
        </ListItemButton>
      </ListItem>
    </List>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "black" }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link
            to="/mainPage"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <img src={logo} alt="LoadMinds Logo" style={{ height: "65px" }} />
          </Link>
        </Typography>
        <Tooltip title="Profile Options">
          <IconButton
            color="inherit"
            onClick={openProfileModal}
            sx={{ padding: "0" }}
          >
            {companyLogoUrl ? (
              <img
                src={companyLogoUrl}
                alt="Company Logo"
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                onError={handleImageError}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ height: "24px", width: "24px" }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
      <Modal
        open={isProfileModalOpen}
        onClose={closeProfileModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 280,
            bgcolor: "background.paper",
            backgroundColor: "black",
            border: "2px solid #3c94fc",
            borderRadius: "15px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#3c94fc",
              textDecoration: "underline",
            }}
          >
            Profile Options
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={handleProfileEdit}
              sx={{ my: 2, border: "1px solid black", color: "white" }}
            >
              🖊️ Edit Profile
            </Button>
            <Button
              onClick={handleMsAuthSetup}
              sx={{ my: 2, border: "1px solid black", color: "white" }}
            >
              🔑 MsAuth Setup
            </Button>
            <Button
              onClick={handleLogout}
              sx={{ my: 2, border: "1px solid black", color: "red" }}
            >
              ⏻ Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
}

export default Navbar;
