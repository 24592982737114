import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  Toolbar,
  Typography,
  Button,
  Snackbar,
  Alert,
  Pagination, // Added for pagination
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import AddIcon from "@mui/icons-material/Add"; // Added for Add Expense
import "jspdf-autotable";
import "./Broker.css";

function TruckPayments() {
  // -------------------- State Variables --------------------
  // Truck & Month
  const [trucksList, setTrucksList] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedTruckNumber, setSelectedTruckNumber] = useState("");

  // --- For date range ---
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Tabs
  const [activeTab, setActiveTab] = useState(0);

  // Data
  const [truckExpenses, setTruckExpenses] = useState([]);
  const [orders, setOrders] = useState([]);

  // Loading Spinner
  const [isLoading, setIsLoading] = useState(false);

  // Dialog for editing an expense
  const [isEditExpenseModalOpen, setIsEditExpenseModalOpen] = useState(false);
  const [editExpense, setEditExpense] = useState({
    Id: "",
    Type: "",
    Price: "",
    Currency: "",
  });

  // Pay totals
  const [cadExpensesTotal, setCadExpensesTotal] = useState("");
  const [usdExpensesTotal, setUsdExpensesTotal] = useState("");
  const [cadLoadsTotal, setCadLoadsTotal] = useState("");
  const [usdLoadsTotal, setUsdLoadsTotal] = useState("");
  const [cadPay, setCadPay] = useState("");
  const [usdPay, setUsdPay] = useState("");
  const [finalPay, setFinalPay] = useState("");
  const [finalPayCurrency, setFinalPayCurrency] = useState("");

  // Explanation of final pay (conversion logic)
  const [finalPayExplanation, setFinalPayExplanation] = useState("");

  // -- Company info states --
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCompanyLogo, setUserCompanyLogo] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userZip, setUserZip] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCustomsEmail, setUserCustomsEmail] = useState("");
  const [userCompanyWebsite, setUserCompanyWebsite] = useState("");
  const [userId, setUserId] = useState("");

  const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
  const [newExpense, setNewExpense] = useState({
    Type: "",
    Price: "",
    Currency: "",
  });

  // Open Add Expense modal
  const openAddExpenseModal = () => {
    setNewExpense({ Type: "", Price: "", Currency: "" });
    setIsAddExpenseModalOpen(true);
  };

  // Handle input changes in Add Expense dialog
  const handleNewExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setNewExpense((prev) => ({ ...prev, [name]: value }));
  };

  // Save new expense
  const handleSaveNewExpense = async () => {
    // Input Validation
    closeAddExpenseModal();
    if (
      !newExpense.Type.trim() ||
      !newExpense.Price.toString().trim() ||
      !newExpense.Currency.trim()
    ) {
      setSnackbar({
        open: true,
        message: "All fields are required to add a new expense.",
        severity: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`/api/add-truck-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Type: newExpense.Type,
          Price: parseFloat(newExpense.Price),
          Currency: newExpense.Currency,
          TruckId: selectedTruck,
          UserId: userId,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      // Re-fetch expenses to update the table
      await fetchExpenses(selectedTruck);

      setSnackbar({
        open: true,
        message: "Expense added successfully!",
        severity: "success",
      });

      // Reset search and pagination
      setExpenseSearchTerm("");
      setCurrentExpensePage(1);

      // Reset newExpense
      setNewExpense({ Type: "", Price: "", Currency: "" });

      // Close the dialog
      setIsAddExpenseModalOpen(false);
    } catch (error) {
      console.error("Failed to add expense:", error);
      setSnackbar({
        open: true,
        message: `Error adding expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Close Add Expense modal
  const closeAddExpenseModal = () => {
    setIsAddExpenseModalOpen(false);
    setNewExpense({ Type: "", Price: "", Currency: "" });
  };

  // Snackbar for notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const navigate = useNavigate();

  // -------------------- Search and Pagination State Variables -------------------- // Added

  // Expenses Tab
  const [expenseSearchTerm, setExpenseSearchTerm] = useState(""); // Added
  const [filteredExpenses, setFilteredExpenses] = useState([]); // Added
  const [currentExpensePage, setCurrentExpensePage] = useState(1); // Added
  const expensesPerPage = 10; // Added

  // Orders Tab
  const [orderSearchTerm, setOrderSearchTerm] = useState(""); // Added
  const [filteredOrders, setFilteredOrders] = useState([]); // Added
  const [currentOrderPage, setCurrentOrderPage] = useState(1); // Added
  const ordersPerPage = 10; // Added

  // -------------------- Dark Theme --------------------
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#000000", paper: "#000000" },
      text: { primary: "#ffffff", secondary: "#aaaaaa" },
      primary: { main: "#3c94fc" },
      secondary: { main: "#f50057" },
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: "#000000",
            padding: "16px 0",
          },
          ul: {
            justifyContent: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#fff",
            backgroundColor: "#000000",
            "&.Mui-selected": {
              backgroundColor: "#3c94fc",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "#111111",
            },
          },
        },
      },
    },
  });

  // -------------------- useEffects --------------------
  // Load company info from sessionStorage
  useEffect(() => {
    const storedCompanyName = sessionStorage.getItem("storageCompanyName");
    const storedCompanyLogo = sessionStorage.getItem("storageCompanyLogo");
    const storedAddress = sessionStorage.getItem("storageAddress");
    const storedCity = sessionStorage.getItem("storageCity");
    const storedState = sessionStorage.getItem("storageState");
    const storedZip = sessionStorage.getItem("storageZip");
    const storedPhone = sessionStorage.getItem("storagePhone");
    const storedCustomsEmail = sessionStorage.getItem("storageCustomsEmail");
    const storedCompanyWebsite = sessionStorage.getItem(
      "storageCompanyWebsite"
    );
    const storedUserId = sessionStorage.getItem("storageUserId");

    if (storedCompanyName) setUserCompanyName(storedCompanyName);
    if (storedCompanyLogo) setUserCompanyLogo(storedCompanyLogo);
    if (storedAddress) setUserAddress(storedAddress);
    if (storedCity) setUserCity(storedCity);
    if (storedState) setUserState(storedState);
    if (storedZip) setUserZip(storedZip);
    if (storedPhone) setUserPhone(storedPhone);
    if (storedCustomsEmail) setUserCustomsEmail(storedCustomsEmail);
    if (storedCompanyWebsite) setUserCompanyWebsite(storedCompanyWebsite);
    if (storedUserId) setUserId(storedUserId);
  }, []);

  // Fetch trucks on mount
  useEffect(() => {
    const fetchTrucks = async () => {
      setIsLoading(true);
      try {
        const storedUserId = sessionStorage.getItem("storageUserId");
        const response = await fetch(
          `/api/fetch-trucks?userId=${encodeURIComponent(storedUserId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setTrucksList(data.result || []);
      } catch (error) {
        console.error("Failed to fetch trucks list:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch trucks. Please try again later.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchTrucks();
  }, []);

  // Define fetchExpenses outside of useEffect
  const fetchExpenses = useCallback(async (truckId) => {
    if (!truckId) {
      setTruckExpenses([]);
      setFilteredExpenses([]); // Added
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/fetch-truck-expenses?truckId=${encodeURIComponent(truckId)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setTruckExpenses(data.result || []);
      setFilteredExpenses(data.result || []); // Added
      setExpenseSearchTerm(""); // Reset search term when new data is fetched
      setCurrentExpensePage(1); // Reset to first page
    } catch (error) {
      console.error("Failed to fetch expenses:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch expenses. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch expenses when a new truck is selected
  useEffect(() => {
    fetchExpenses(selectedTruck);
  }, [selectedTruck, fetchExpenses]);

  // Fetch orders when truckNumber & month are selected
  useEffect(() => {
    if (!selectedTruckNumber || !startDate || !endDate) {
      setOrders([]);
      setFilteredOrders([]); // Added
      setOrderSearchTerm(""); // Reset search term
      setCurrentOrderPage(1); // Reset to first page
      return;
    }

    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-loads-range-truck?truckId=${encodeURIComponent(
            selectedTruckNumber
          )}&startDate=${encodeURIComponent(
            startDate
          )}&endDate=${encodeURIComponent(endDate)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setOrders(data.result || []);
        setFilteredOrders(data.result || []); // Added
        setOrderSearchTerm(""); // Reset search term
        setCurrentOrderPage(1); // Reset to first page
      } catch (error) {
        console.error("Failed to fetch orders:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch orders. Please try again later.",
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
  }, [selectedTruckNumber, startDate, endDate]);

  // -------------------- Handlers --------------------
  // Select Truck
  const handleChangeTruck = (event) => {
    const truckId = event.target.value;
    const truckObj = trucksList.find((t) => t.Id === truckId);
    setSelectedTruck(truckId);

    if (truckObj) {
      setSelectedTruckNumber(truckObj.Number);
    } else {
      setSelectedTruckNumber("");
    }
  };

  // For date fields
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // Tab switch
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Edit order
  const handleEditOrder = (orderData) => {
    sessionStorage.setItem("orderData", JSON.stringify(orderData));
    navigate("/edit-order");
  };

  // Edit expense
  const handleEditExpenseClick = (expense) => {
    setEditExpense({
      Id: expense.Id,
      Type: expense.Type,
      Price: expense.Price,
      Currency: expense.Currency,
    });
    setIsEditExpenseModalOpen(true);
  };

  // Delete expense
  const handleDeleteExpenseClick = async (expense) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete expense: ${expense.Type}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-truck-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ Id: expense.Id }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setTruckExpenses((prev) => prev.filter((exp) => exp.Id !== expense.Id));
      setFilteredExpenses((prev) =>
        prev.filter((exp) => exp.Id !== expense.Id)
      ); // Added
      setSnackbar({
        open: true,
        message: "Expense deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to delete Expense:", error);
      setSnackbar({
        open: true,
        message: `Error deleting Expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input changes in Edit Expense dialog
  const handleEditExpenseInputChange = (e) => {
    const { name, value } = e.target;
    setEditExpense((prev) => ({ ...prev, [name]: value }));
  };

  // Close Edit Expense modal
  const closeEditExpenseModal = () => {
    setIsEditExpenseModalOpen(false);
    setEditExpense({ Id: "", Type: "", Price: "", Currency: "" });
  };

  // **Updated handleSaveEditExpense to align with Add Broker workflow**
  const handleSaveEditExpense = async () => {
    // Input Validation
    if (
      !editExpense.Type.trim() ||
      !editExpense.Price.toString().trim() ||
      !editExpense.Currency.trim()
    ) {
      setSnackbar({
        open: true,
        message: "All fields are required to edit this expense.",
        severity: "warning",
      });
      return;
    }

    // Close the dialog immediately
    closeEditExpenseModal();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/update-truck-expense`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Id: editExpense.Id,
          Type: editExpense.Type,
          Price: parseFloat(editExpense.Price),
          Currency: editExpense.Currency,
          TruckId: selectedTruck,
          UserId: userId,
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! Status: ${response.status}`
        );
      }

      // Re-fetch expenses to update the table
      await fetchExpenses(selectedTruck);

      setSnackbar({
        open: true,
        message: "Expense updated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to edit expense:", error);
      setSnackbar({
        open: true,
        message: `Error editing expense: ${error.message}`,
        severity: "error",
      });
    } finally {
      setIsLoading(false);
      setEditExpense({ Id: "", Type: "", Price: "", Currency: "" });
    }
  };

  // -------------------- Search Handlers -------------------- // Added
  // Expenses Search
  const handleExpenseSearchChange = (e) => {
    const term = e.target.value;
    setExpenseSearchTerm(term);
    if (term.trim() === "") {
      setFilteredExpenses(truckExpenses);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = truckExpenses.filter((expense) =>
        Object.values(expense).some((val) =>
          String(val).toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredExpenses(filtered);
      setCurrentExpensePage(1); // Reset to first page
    }
  };

  // Orders Search
  const handleOrderSearchChange = (e) => {
    const term = e.target.value;
    setOrderSearchTerm(term);
    if (term.trim() === "") {
      setFilteredOrders(orders);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = orders.filter((order) =>
        Object.values(order).some((val) =>
          String(val).toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredOrders(filtered);
      setCurrentOrderPage(1); // Reset to first page
    }
  };

  // -------------------- Final Pay Calculation with Detailed Explanation --------------------
  const handleCalculateAll = () => {
    // Reset final pay and explanation
    setFinalPay("");
    setFinalPayCurrency("");
    setFinalPayExplanation("");

    // Sum expenses
    const cadExp = truckExpenses
      .filter((exp) => exp.Currency === "CAD")
      .reduce((acc, exp) => acc + parseFloat(exp.Price || 0), 0);
    setCadExpensesTotal(cadExp.toFixed(2));

    const usdExp = truckExpenses
      .filter((exp) => exp.Currency === "USD")
      .reduce((acc, exp) => acc + parseFloat(exp.Price || 0), 0);
    setUsdExpensesTotal(usdExp.toFixed(2));

    // Sum loads
    const cadLd = orders
      .filter((ord) => ord.Currency === "CAD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    setCadLoadsTotal(cadLd.toFixed(2));

    const usdLd = orders
      .filter((ord) => ord.Currency === "USD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    setUsdLoadsTotal(usdLd.toFixed(2));

    // Basic pay
    const newCadPay = cadLd - cadExp; // could be negative, zero, or positive
    const newUsdPay = usdLd - usdExp; // could be negative, zero, or positive
    setCadPay(newCadPay.toFixed(2));
    setUsdPay(newUsdPay.toFixed(2));

    // Negative offset scenarios:
    // A) Negative CAD, Positive USD => Convert negative CAD to negative USD
    if (newCadPay < 0 && newUsdPay > 0) {
      const rateInput = window.prompt(
        "CAD Pay is negative but you have positive USD. Enter CAD→USD conversion rate (e.g. 0.75):"
      );
      if (!rateInput) return;
      const rate = parseFloat(rateInput);
      if (isNaN(rate) || rate <= 0) {
        setSnackbar({
          open: true,
          message: "Invalid conversion rate.",
          severity: "error",
        });
        return;
      }

      const negativeCadInUsd = newCadPay * rate;
      const finalUsd = newUsdPay + negativeCadInUsd;

      setFinalPay(finalUsd.toFixed(2));
      setFinalPayCurrency("USD");

      // Explanation
      setFinalPayExplanation(
        `We had negative CAD pay of ${newCadPay.toFixed(
          2
        )}.\nConverted to USD using rate ${rate.toFixed(2)} => ` +
          `(${newCadPay.toFixed(2)} × ${rate.toFixed(
            2
          )}) = ${negativeCadInUsd.toFixed(
            2
          )}.\nThen we add your positive USD pay of ${newUsdPay.toFixed(
            2
          )}, resulting in final USD pay: ${finalUsd.toFixed(2)}.`
      );
    }
    // B) Negative USD, Positive CAD => Convert negative USD to negative CAD
    else if (newUsdPay < 0 && newCadPay > 0) {
      const rateInput = window.prompt(
        "USD Pay is negative but you have positive CAD. Enter USD→CAD conversion rate (e.g. 1.33):"
      );
      if (!rateInput) return;
      const rate = parseFloat(rateInput);
      if (isNaN(rate) || rate <= 0) {
        setSnackbar({
          open: true,
          message: "Invalid conversion rate.",
          severity: "error",
        });
        return;
      }

      const negativeUsdInCad = newUsdPay * rate;
      const finalCad = newCadPay + negativeUsdInCad;

      setFinalPay(finalCad.toFixed(2));
      setFinalPayCurrency("CAD");

      // Explanation
      setFinalPayExplanation(
        `We had negative USD pay of ${newUsdPay.toFixed(
          2
        )}.\nConverted to CAD using rate ${rate.toFixed(2)} => ` +
          `(${newUsdPay.toFixed(2)} × ${rate.toFixed(
            2
          )}) = ${negativeUsdInCad.toFixed(
            2
          )}.\nThen we add your positive CAD pay of ${newCadPay.toFixed(
            2
          )}, resulting in final CAD pay: ${finalCad.toFixed(2)}.`
      );
    }
  };

  // -------------------- PDF Helper Functions --------------------
  // Convert an image URL (logo) to base64
  const convertLogoToBase64 = async (logoUrl) => {
    if (!logoUrl) return null;
    try {
      const response = await fetch(logoUrl, { mode: "cors" });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading company logo:", error);
      return null;
    }
  };

  // Add a company info & logo header
  const addCompanyHeader = async (doc) => {
    let logoBase64Data = await convertLogoToBase64(userCompanyLogo);

    const pageWidth = doc.internal.pageSize.getWidth();
    const marginLeft = 40;
    const marginRight = 40;
    let yPosition = 50; // Starting Y

    const logoWidth = 140;
    const logoHeight = 60;

    if (logoBase64Data) {
      doc.addImage(
        logoBase64Data,
        "PNG",
        pageWidth - marginRight - logoWidth,
        yPosition,
        logoWidth,
        logoHeight
      );
    }

    // Company Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0); // black text
    doc.text(`${userCompanyName || ""}`, marginLeft, yPosition + 20);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);

    let headerYPos = yPosition + 40;

    if (userAddress) {
      doc.text(userAddress, marginLeft, headerYPos);
      headerYPos += 12;
    }
    if ((userCity && userState) || userZip) {
      doc.text(`${userCity}, ${userState} ${userZip}`, marginLeft, headerYPos);
      headerYPos += 12;
    }
    if (userPhone) {
      doc.setFont("helvetica", "bold");
      doc.text("Phone:", marginLeft, headerYPos);
      doc.setFont("helvetica", "normal");
      doc.text(userPhone, marginLeft + 50, headerYPos);
      headerYPos += 12;
    }
    if (userCustomsEmail) {
      doc.setFont("helvetica", "bold");
      doc.text("Email:", marginLeft, headerYPos);
      doc.setFont("helvetica", "italic");
      doc.text(userCustomsEmail, marginLeft + 50, headerYPos);
      headerYPos += 12;
    }
    if (userCompanyWebsite) {
      doc.setFont("helvetica", "bold");
      doc.text("Website:", marginLeft, headerYPos);
      doc.setFont("helvetica", "italic");
      doc.text(userCompanyWebsite, marginLeft + 60, headerYPos);
      headerYPos += 12;
    }

    const logoBottom = yPosition + logoHeight;
    headerYPos = Math.max(headerYPos, logoBottom);

    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(
      marginLeft,
      headerYPos + 10,
      pageWidth - marginRight,
      headerYPos + 10
    );

    return headerYPos + 30;
  };

  const createPdfHeading = (doc, title, startY) => {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.setTextColor(0); // black
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(title, pageWidth / 2, startY, { align: "center" });

    doc.setLineWidth(1);
    doc.setDrawColor(0);
    doc.line(40, startY + 10, pageWidth - 40, startY + 10);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    return startY + 50;
  };

  // Print truck & month by name
  const printTruckAndMonth = (doc, yPos) => {
    doc.setTextColor(0);

    // 1) TRUCK NUMBER line
    {
      // Bold label
      doc.setFont("helvetica", "bold");
      const label1 = "Truck Number: ";
      const label1X = 40; // start x
      doc.text(label1, label1X, yPos);

      // Measure label
      const label1Width = doc.getTextWidth(label1);

      // Normal for the value
      doc.setFont("helvetica", "normal");
      const truckNumX = label1X + label1Width; // right after label
      doc.text(`${selectedTruckNumber}`, truckNumX, yPos);

      yPos += 20; // move down for next line
    }

    // 2) PERIOD line
    {
      // Bold label
      doc.setFont("helvetica", "bold");
      const label2 = "Period: ";
      const label2X = 40;
      doc.text(label2, label2X, yPos);

      // Measure label
      const label2Width = doc.getTextWidth(label2);

      // Normal for startDate
      doc.setFont("helvetica", "normal");
      const startDateX = label2X + label2Width;
      doc.text(`${startDate}`, startDateX, yPos);

      // Measure startDate text
      const startDateWidth = doc.getTextWidth(`${startDate}`);

      // Bold " To "
      doc.setFont("helvetica", "bold");
      const toText = " To ";
      const toX = startDateX + startDateWidth;
      doc.text(toText, toX, yPos);

      // Measure " To "
      const toWidth = doc.getTextWidth(toText);

      // Normal for endDate
      doc.setFont("helvetica", "normal");
      const endDateX = toX + toWidth + 2;
      doc.text(`${endDate}`, endDateX, yPos);

      yPos += 20;
    }

    return yPos;
  };

  const addFooterNote = (doc) => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setLineWidth(0.5);
    doc.setDrawColor(0);
    doc.line(40, pageHeight - 40, pageWidth - 40, pageHeight - 40);
    doc.setFontSize(9);
    doc.setTextColor(100);
    doc.text("Generated by LoadMinds ©", 40, pageHeight - 25);
    doc.setTextColor(0);
  };

  // **Expenses PDF** (with totals at bottom)
  const handleDownloadExpensesPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);

    nextY = createPdfHeading(doc, "Expenses Report", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const columns = [
      { header: "Type", dataKey: "Type" },
      { header: "Price", dataKey: "Price" },
      { header: "Currency", dataKey: "Currency" },
    ];

    const rows = truckExpenses.map((exp) => ({
      Type: exp.Type,
      Price: exp.Price,
      Currency: exp.Currency,
    }));

    // Summaries for the bottom
    const totalExpensesCAD = truckExpenses
      .filter((exp) => exp.Currency === "CAD")
      .reduce((acc, exp) => acc + parseFloat(exp.Price || 0), 0);
    const totalExpensesUSD = truckExpenses
      .filter((exp) => exp.Currency === "USD")
      .reduce((acc, exp) => acc + parseFloat(exp.Price || 0), 0);

    doc.autoTable({
      columns,
      body: rows,
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255], // white
        textColor: [0, 0, 0], // black
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontStyle: "bold",
      },
      // No alternate row fill
      alternateRowStyles: {},
    });

    // Where the table ended
    let summaryY = doc.lastAutoTable.finalY + 30;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      `Total Expenses (CAD): ${totalExpensesCAD.toFixed(2)}`,
      40,
      summaryY
    );
    summaryY += 24;
    doc.text(
      `Total Expenses (USD): ${totalExpensesUSD.toFixed(2)}`,
      40,
      summaryY
    );

    addFooterNote(doc);
    const fileName = `Expenses_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // **Orders PDF** (with totals at bottom)
  const handleDownloadOrdersPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);

    nextY = createPdfHeading(doc, "Orders Report", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const columns = [
      { header: "Pickup Date", dataKey: "PickupDate" },
      { header: "Pickup Location", dataKey: "PickupLocation" },
      { header: "Delivery Location", dataKey: "DeliveryLocation" },
      { header: "Price", dataKey: "PriceKept" },
      { header: "Currency", dataKey: "Currency" },
    ];

    const rows = orders.map((ord) => ({
      PickupDate: ord.PickupDate,
      PickupLocation: ord.PickupLocation,
      DeliveryLocation: ord.DeliveryLocation,
      PriceKept: ord.PriceKept,
      Currency: ord.Currency,
    }));

    // Summaries for the bottom
    const totalOrdersCAD = orders
      .filter((ord) => ord.Currency === "CAD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);
    const totalOrdersUSD = orders
      .filter((ord) => ord.Currency === "USD")
      .reduce((acc, ord) => acc + parseFloat(ord.PriceKept || 0), 0);

    doc.autoTable({
      columns,
      body: rows,
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontStyle: "bold",
      },
      alternateRowStyles: {},
    });

    // Where the table ended
    let summaryY = doc.lastAutoTable.finalY + 30;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(`Total Freight (CAD): ${totalOrdersCAD.toFixed(2)}`, 40, summaryY);
    summaryY += 24;
    doc.text(`Total Freight (USD): ${totalOrdersUSD.toFixed(2)}`, 40, summaryY);

    addFooterNote(doc);
    const fileName = `Orders_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // **Pay PDF** (highlight final row for CAD Pay & USD Pay, plus show explanation in lighter gray)
  const handleDownloadPayPDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    let nextY = await addCompanyHeader(doc);

    nextY = createPdfHeading(doc, "Pay Summary", nextY);
    nextY = printTruckAndMonth(doc, nextY);

    const payColumns = [
      { header: "", dataKey: "labelLeft" },
      { header: "", dataKey: "valueLeft" },
      { header: "", dataKey: "labelRight" },
      { header: "", dataKey: "valueRight" },
    ];

    const payRows = [
      {
        labelLeft: "CAD Loads",
        valueLeft: cadLoadsTotal,
        labelRight: "USD Loads",
        valueRight: usdLoadsTotal,
      },
      {
        labelLeft: "CAD Expenses",
        valueLeft: cadExpensesTotal,
        labelRight: "USD Expenses",
        valueRight: usdExpensesTotal,
      },
      {
        labelLeft: "CAD Pay",
        valueLeft: cadPay,
        labelRight: "USD Pay",
        valueRight: usdPay,
      },
    ];

    doc.autoTable({
      startY: nextY,
      margin: { left: 40, right: 40 },
      styles: {
        fontSize: 10,
        font: "helvetica",
        cellPadding: 6,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        textColor: [0, 0, 0],
      },
      bodyStyles: {
        textColor: [0, 0, 0],
      },
      head: [], // no header row
      columns: payColumns,
      body: payRows.map((row) => [
        row.labelLeft,
        row.valueLeft,
        row.labelRight,
        row.valueRight,
      ]),
      alternateRowStyles: {},
      didParseCell: (hookData) => {
        // highlight final row (CAD Pay / USD Pay)
        if (hookData.section === "body" && hookData.row.index === 2) {
          hookData.cell.styles.fillColor = [220, 220, 220]; // light gray
          hookData.cell.styles.fontStyle = "bold";
        }
      },
    });

    const tableData = doc.lastAutoTable;
    let newY = tableData.finalY + 20;

    if (finalPay) {
      doc.setDrawColor(0);
      doc.setLineWidth(1);

      const boxX = 40;
      const boxY = newY;
      const boxWidth = doc.internal.pageSize.getWidth() - 80;
      const boxHeight = 100; // a bit taller for explanation text

      doc.rect(boxX, boxY, boxWidth, boxHeight);

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.setTextColor(0);
      doc.text("Final Pay", boxX + 15, boxY + 25);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(11);
      doc.setTextColor(0);
      doc.text(`${finalPay} ${finalPayCurrency}`, boxX + 15, boxY + 45);

      // If there's an explanation, print it in gray
      if (finalPayExplanation) {
        doc.setTextColor(120); // a lighter gray
        const explanationLines = doc.splitTextToSize(
          finalPayExplanation,
          boxWidth - 30
        );
        doc.text(explanationLines, boxX + 15, boxY + 65);
        // revert color to black if you plan to draw more text afterwards
        doc.setTextColor(0);
      }
    }

    addFooterNote(doc);
    const fileName = `PaySummary_${selectedTruckNumber || "NoTruck"}.pdf`;
    doc.save(fileName);
  };

  // -------------------- Render --------------------
  const renderDateRangeInputs = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Select Date Range
        </Typography>
        <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            sx={{
              input: { color: "#fff" },
              "& .MuiInputLabel-root": { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#3c94fc" },
                "&:hover fieldset": { borderColor: "#fff" },
                "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            sx={{
              input: { color: "#fff" },
              "& .MuiInputLabel-root": { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#3c94fc" },
                "&:hover fieldset": { borderColor: "#fff" },
                "&.Mui-focused fieldset": { borderColor: "#3c94fc" },
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chat-container">
        <SideNavBar />
        <div className="broker-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="broker-body">
            <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
              {/* Main Title */}
              <Typography
                variant="h4"
                component="div"
                sx={{
                  m: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Truck Payments
              </Typography>

              {/* Select Truck & Month */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                gap={4}
                sx={{ p: 2 }}
              >
                {/* Select Truck */}
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Select Truck
                  </Typography>
                  <Select
                    value={selectedTruck}
                    onChange={handleChangeTruck}
                    displayEmpty
                    sx={{
                      minWidth: 200,
                      color: "#fff",
                      "& .MuiSelect-icon": { color: "#fff" },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3c94fc",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3c94fc",
                      },
                    }}
                    inputProps={{ "aria-label": "Select Truck" }}
                  >
                    <MenuItem value="">
                      <em>-- Select Truck --</em>
                    </MenuItem>
                    {trucksList.map((truck) => (
                      <MenuItem key={truck.Id} value={truck.Id}>
                        {truck.Number}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                {/* Date Range */}
                {renderDateRangeInputs()}
              </Box>

              {/* Loading Spinner or Tabs */}
              {isLoading ? (
                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Spinner sx={{ color: "#3c94fc" }} />
                </Box>
              ) : (
                <>
                  {/* Tabs for Expenses / Orders / Pay */}
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      textColor="primary"
                      indicatorColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label="Expenses" />
                      <Tab label="Orders" />
                      <Tab label="Pay" />
                    </Tabs>
                  </Box>

                  {/* Expenses Tab */}
                  {activeTab === 0 && (
                    <Box sx={{ p: 2 }}>
                      {/* Search Bar and Download Button */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        flexDirection={{ xs: "column", sm: "row" }} // Responsive
                        gap={2}
                      >
                        {/* Search Bar */}
                        <TextField
                          variant="outlined"
                          value={expenseSearchTerm}
                          onChange={handleExpenseSearchChange}
                          placeholder="Search Expenses"
                          size="small"
                          sx={{ width: { xs: "100%", sm: "40%" } }}
                        />
                        {/* Buttons Container */}
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap={2}
                          sx={{ width: { xs: "100%", sm: "auto" } }}
                        >
                          {/* Add Expense Button */}
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={openAddExpenseModal}
                          >
                            Add Expense
                          </Button>
                          {/* Download PDF Button */}
                          <Button
                            variant="outlined"
                            onClick={handleDownloadExpensesPDF}
                          >
                            Download PDF
                          </Button>
                        </Box>
                      </Box>

                      <TableContainer component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Type</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Currency</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredExpenses.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={4} align="center">
                                  No expenses found
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredExpenses
                                .slice(
                                  (currentExpensePage - 1) * expensesPerPage,
                                  currentExpensePage * expensesPerPage
                                )
                                .map((exp) => (
                                  <TableRow key={exp.Id}>
                                    <TableCell>{exp.Type}</TableCell>
                                    <TableCell>{exp.Price}</TableCell>
                                    <TableCell>{exp.Currency}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        sx={{ color: "#3c94fc" }}
                                        onClick={() =>
                                          handleEditExpenseClick(exp)
                                        }
                                        aria-label="edit"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        sx={{ color: "#f44336" }}
                                        onClick={() =>
                                          handleDeleteExpenseClick(exp)
                                        }
                                        aria-label="delete"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {/* Pagination */}
                      {filteredExpenses.length > expensesPerPage && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(
                              filteredExpenses.length / expensesPerPage
                            )}
                            page={currentExpensePage}
                            onChange={(event, value) =>
                              setCurrentExpensePage(value)
                            }
                            color="primary"
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Orders Tab */}
                  {activeTab === 1 && (
                    <Box sx={{ p: 2 }}>
                      {/* Search Bar and Download Button */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                        flexDirection={{ xs: "column", sm: "row" }} // Responsive
                        gap={2}
                      >
                        {/* Search Bar */}
                        <TextField
                          variant="outlined"
                          value={orderSearchTerm}
                          onChange={handleOrderSearchChange}
                          placeholder="Search Orders"
                          size="small"
                          sx={{ width: { xs: "100%", sm: "40%" } }}
                        />
                        {/* Download PDF Button */}
                        <Button
                          variant="outlined"
                          onClick={handleDownloadOrdersPDF}
                        >
                          Download PDF
                        </Button>
                      </Box>

                      <TableContainer component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Pickup Date</TableCell>
                              <TableCell>Pickup Location</TableCell>
                              <TableCell>Delivery Location</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Currency</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredOrders.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  No orders found
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredOrders
                                .slice(
                                  (currentOrderPage - 1) * ordersPerPage,
                                  currentOrderPage * ordersPerPage
                                )
                                .map((order, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell>{order.PickupDate}</TableCell>
                                    <TableCell>
                                      {order.PickupLocation}
                                    </TableCell>
                                    <TableCell>
                                      {order.DeliveryLocation}
                                    </TableCell>
                                    <TableCell>{order.PriceKept}</TableCell>
                                    <TableCell>{order.Currency}</TableCell>
                                    <TableCell>
                                      <IconButton
                                        sx={{ color: "#3c94fc" }}
                                        onClick={() => handleEditOrder(order)}
                                        aria-label="edit"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      {/* If you have delete functionality for orders, you can add it here */}
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {/* Pagination */}
                      {filteredOrders.length > ordersPerPage && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(
                              filteredOrders.length / ordersPerPage
                            )}
                            page={currentOrderPage}
                            onChange={(event, value) =>
                              setCurrentOrderPage(value)
                            }
                            color="primary"
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* Pay Tab */}
                  {activeTab === 2 && (
                    <Box sx={{ p: 2 }}>
                      <Button
                        variant="outlined"
                        sx={{ mb: 2 }}
                        onClick={handleDownloadPayPDF}
                      >
                        Download PDF
                      </Button>

                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Pay
                      </Typography>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: 2,
                        }}
                      >
                        {/* CAD Loads */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">CAD Loads</Typography>
                          <TextField
                            variant="outlined"
                            value={cadLoadsTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>

                        {/* USD Loads */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">USD Loads</Typography>
                          <TextField
                            variant="outlined"
                            value={usdLoadsTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>

                        {/* CAD Expenses */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            CAD Expenses
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={cadExpensesTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>

                        {/* USD Expenses */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">
                            USD Expenses
                          </Typography>
                          <TextField
                            variant="outlined"
                            value={usdExpensesTotal}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>

                        {/* CAD Pay */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">CAD Pay</Typography>
                          <TextField
                            variant="outlined"
                            value={cadPay}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>

                        {/* USD Pay */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Typography variant="subtitle2">USD Pay</Typography>
                          <TextField
                            variant="outlined"
                            value={usdPay}
                            InputProps={{ readOnly: true }}
                            sx={{
                              input: { color: "#fff" },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#3c94fc",
                              },
                            }}
                          />
                        </Box>
                      </Box>

                      {/* Final Pay Box if needed */}
                      {finalPay && (
                        <Box
                          sx={{
                            mt: 3,
                            p: 2,
                            border: "1px solid #3c94fc",
                            borderRadius: 2,
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            Final Pay
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: 18 }}>
                            {finalPay} {finalPayCurrency}
                          </Typography>

                          {/* Show explanation if it exists, light grey color */}
                          {finalPayExplanation && (
                            <Box sx={{ mt: 1 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontStyle: "italic",
                                  whiteSpace: "pre-line",
                                  color: "#ccc", // lighter gray in the UI
                                }}
                              >
                                {finalPayExplanation}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}

                      <Box
                        sx={{
                          mt: 3,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleCalculateAll}
                        >
                          Calculate All
                        </Button>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Card>
          </div>
        </div>
      </div>

      {/* Edit Expense Dialog */}
      <Dialog
        open={isEditExpenseModalOpen}
        onClose={closeEditExpenseModal}
        fullWidth
      >
        <DialogTitle>Edit Expense</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              label="Type"
              name="Type"
              value={editExpense.Type}
              onChange={handleEditExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
            />
            <TextField
              label="Price"
              name="Price"
              type="number"
              value={editExpense.Price}
              onChange={handleEditExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
            />
            <TextField
              label="Currency"
              name="Currency"
              select
              value={editExpense.Currency}
              onChange={handleEditExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
            >
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              {/* Add more currencies as needed */}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveEditExpense}
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="small" /> : "Save Changes"}
          </Button>
          <IconButton
            onClick={closeEditExpenseModal}
            aria-label="close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 9000,
              border: "2px solid red",
            }}
          >
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/* Add Expense Dialog */}
      <Dialog
        open={isAddExpenseModalOpen}
        onClose={closeAddExpenseModal}
        fullWidth
      >
        <DialogTitle>Add New Expense</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <label>Type</label>
            <TextField
              name="Type"
              value={newExpense.Type}
              onChange={handleNewExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
              margin="dense"
            />
            <label>Price</label>
            <TextField
              name="Price"
              type="number"
              value={newExpense.Price}
              onChange={handleNewExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
              margin="dense"
            />
            <label>Currency</label>
            <TextField
              name="Currency"
              select
              value={newExpense.Currency}
              onChange={handleNewExpenseInputChange}
              variant="outlined"
              required
              sx={{
                input: { color: "#fff" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3c94fc",
                },
              }}
              margin="dense"
            >
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              {/* Add more currencies as needed */}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveNewExpense}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="small" /> : "Add Expense"}
          </Button>
          <IconButton
            onClick={closeAddExpenseModal}
            aria-label="close"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 9000,
              border: "2px solid red",
            }}
          >
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default TruckPayments;
