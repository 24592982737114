import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "./authConfig";
import { jwtDecode } from "jwt-decode";
import Home from "./Home";
import Paps from "./Paps";
import BrokerList from "./BrokerList";
import EditPod from "./EditPod";
import Pars from "./Pars";
import Edit from "./Edit";
import Chat from "./Chat";
import PodHistory from "./PodHistory";
import Pod from "./Pod";
import "./App.css";
import Navbar from "./Navbar";
import PdfEditor from "./PdfEditor";
import MainPage from "./MainPage";
import LoadQuote from "./LoadQuote";
import DocumentGeneration from "./DocumentGeneration";
import EmailGeneration from "./EmailGeneration";
import OrderHistory from "./OrderHistory";
import EditOrder from "./EditOrder";
import Orders from "./Orders";
import Trucks from "./Trucks";
import EditTruck from "./EditTruck";
import TruckPayments from "./TruckPayments";
import Invoices from "./Invoices";
import CreateInvoice from "./CreateInvoice";
import Invoicing from "./Invoicing";
import MsAuthSetup from "./MsAuthSetup";

const AppProvider = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);
    if (accounts && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }

    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        const account = event.payload.account;
        instance.setActiveAccount(account);
        setIsAuthenticated(true);

        if (account && account.idToken) {
          const idTokenClaims = jwtDecode(account.idToken);
          console.log(idTokenClaims);
          const currentUserId = idTokenClaims.sub;

          setUserId(currentUserId);

          if (
            currentUserId &&
            currentUserId !== "undefined" &&
            currentUserId !== null &&
            currentUserId !== ""
          ) {
            // console.log(idTokenClaims);
            sessionStorage.setItem("storageUserId", currentUserId);
            sessionStorage.setItem(
              "storageCompanyName",
              idTokenClaims.extension_CompanyName
            );
            sessionStorage.setItem(
              "storageParsStart",
              idTokenClaims.extension_PARSCarrierCode
            );
            sessionStorage.setItem(
              "storagePapsStart",
              idTokenClaims.extension_PAPSCarrierCode
            );
            sessionStorage.setItem(
              "storageCustomsEmail",
              idTokenClaims.extension_CustomsEmail
            );
            sessionStorage.setItem(
              "storageFirstName",
              idTokenClaims.given_name
            );
            sessionStorage.setItem(
              "storageLastName",
              idTokenClaims.family_name
            );
            sessionStorage.setItem(
              "storageAddress",
              idTokenClaims.streetAddress
            );
            sessionStorage.setItem("storageCity", idTokenClaims.city);
            sessionStorage.setItem("storageState", idTokenClaims.state);
            sessionStorage.setItem("storageZip", idTokenClaims.postalCode);
            sessionStorage.setItem(
              "storageCompanyLogo",
              idTokenClaims.extension_CompanyLogo
            );
            sessionStorage.setItem(
              "storageCompanyWebsite",
              idTokenClaims.extension_CompanyWebsiteURL
            );
            sessionStorage.setItem(
              "storagePhone",
              idTokenClaims.extension_PhoneNumber
            );

            sendUserEmailToBackend(idTokenClaims.extension_CustomsEmail);
          }
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        setIsAuthenticated(false);
        setUserId(null);
      }
    });

    return () => instance.removeEventCallback(callbackId);
  }, [instance]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(console.error);
    }
  }, [isAuthenticated, inProgress, instance]);

  const sendUserEmailToBackend = (userEmail) => {
    const url = "/api/store-user-email";

    const data = {
      userEmail: userEmail,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        {React.cloneElement(children, { userId })}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Authenticating...</div>
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ instance }) {
  const { inProgress } = useMsal();
  return (
    <MsalProvider instance={instance}>
      <AppProvider>
        <Router>
          <Navbar
            b2cPolicies={b2cPolicies}
            instance={instance}
            inProgress={inProgress}
          />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/broker-list" element={<BrokerList />} />
            <Route path="/customs" element={<Home />} />
            <Route path="/paps" element={<Paps />} />
            <Route path="/pars" element={<Pars />} />
            <Route path="/edit" element={<Edit />} />
            <Route path="/edit-pod" element={<EditPod />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/pod-history" element={<PodHistory />} />
            <Route path="/edit-pdf" element={<PdfEditor />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/mainPage" element={<MainPage />} />
            <Route path="/load-quote" element={<LoadQuote />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/edit-order" element={<EditOrder />} />
            <Route
              path="/document-generation"
              element={<DocumentGeneration />}
            />
            <Route path="/email-generation" element={<EmailGeneration />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/trucks" element={<Trucks />} />
            <Route path="/edit-truck" element={<EditTruck />} />
            <Route path="/truck-payments" element={<TruckPayments />} />
            <Route path="pod" element={<Pod />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="create-invoice" element={<CreateInvoice />} />
            <Route path="/invoicing/:orderNumber" element={<Invoicing />} />
            <Route path="/ms-auth-setup" element={<MsAuthSetup />} />
          </Routes>
        </Router>
      </AppProvider>
    </MsalProvider>
  );
}

export default App;
