import React, { useState, useEffect, useRef } from "react";
import { Button, Toolbar } from "@mui/material";
import Spinner from "./Spinner";
import SideNavBar from "./SideNavBar";
import { useNavigate, useLocation } from "react-router-dom";
import "./Edit.css";
import OrderDetails from "./OrderDetails";

// ---- Supabase ----
import { createClient } from "@supabase/supabase-js";
const supabaseUrl = "https://bkyisyxrbccchnmvksvj.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJreWlzeXhyYmNjY2hubXZrc3ZqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQzMDMyNzcsImV4cCI6MjA0OTg3OTI3N30.qqnqqNWQK27zUzmuqydQA5lmS3-x_Yd41mqykrUkBcc";
const supabase = createClient(supabaseUrl, supabaseKey);

function EditOrder() {
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // grab location from React Router
  const location = useLocation();
  const fromPage = location.state?.from || null;

  useEffect(() => {
    const loadNumber = sessionStorage.getItem("selectedLoadNumber");
    if (!loadNumber) {
      navigate("/order-history");
      return;
    }

    const fetchLoadData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/get-load?loadNumber=${encodeURIComponent(loadNumber)}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const result = data.result || {};

        let parsedPickupAddresses = [];
        let parsedDeliveryAddresses = [];
        let parsedCompanyAddress = {
          companyName: "",
          streetAddress: "",
          city: "",
          state: "",
          zip: "",
          phoneNumber: "",
        };

        try {
          if (result.PickupAddress) {
            parsedPickupAddresses = JSON.parse(result.PickupAddress);
          }
          if (result.DeliveryAddress) {
            parsedDeliveryAddresses = JSON.parse(result.DeliveryAddress);
          }
          if (result.CompanyAddress) {
            parsedCompanyAddress = JSON.parse(result.CompanyAddress);
          }
        } catch (err) {
          console.error("Error parsing JSON fields:", err);
        }

        setEditData({
          // Mapped fields
          orderNumber: result.CustomerOrderNumber || "",
          dbOrderNumber: result.OrderNumber,
          pickupDate: result.PickupDate || "",
          pickupLocation: result.PickupLocation || "",
          deliveryDate: result.DeliveryDate || "",
          deliveryLocation: result.DeliveryLocation || "",
          rate: result.Price || 0,
          currency: result.Currency || "",
          priceGiven: result.PriceKept || 0,
          tripType: result.TripType || "",
          equipment: result.CarrierOrEquipment || "",
          status: result.Status || "",
          accountingEmail: result.AccountingEmail || "",
          dispatcherEmail: result.DispatchEmail || "",
          pickups: parsedPickupAddresses,
          deliveries: parsedDeliveryAddresses,
          loadGivingCompany: {
            companyName: parsedCompanyAddress.companyName || "",
            streetAddress: parsedCompanyAddress.streetAddress || "",
            city: parsedCompanyAddress.city || "",
            state: parsedCompanyAddress.state || "",
            zip: parsedCompanyAddress.zip || "",
            phoneNumber: parsedCompanyAddress.phoneNumber || "",
          },
          pdfUrl: result.Pdf || null,
          dateAdded: result.DateAdded || "",
        });
      } catch (error) {
        console.error("Failed to fetch load data:", error);
        alert(`Error fetching load data: ${error.message}`);
        navigate("/order-history");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoadData();
  }, [navigate]);

  const handleSave = async () => {
    if (!editData) return;
    setIsLoading(true);

    try {
      // 1) If a new file was selected, delete the old one (if any), then upload the new file
      let updatedPdfUrl = editData.pdfUrl;

      if (newFile) {
        // Attempt to remove the old file (if it exists in Supabase)
        if (editData.pdfUrl) {
          try {
            // Extract the relative path after "/pdf-storage/" in the public URL
            const oldFilePathPart = editData.pdfUrl.split("/pdf-storage/")[1];
            const oldFilePath = decodeURIComponent(oldFilePathPart);

            const { error: removeError } = await supabase.storage
              .from("pdf-storage")
              .remove([oldFilePath]);

            if (removeError) {
              console.error("Error removing old file:", removeError.message);
            } else {
              console.log("Old file removed successfully:", oldFilePath);
            }
          } catch (removeErr) {
            console.error("Error removing old file:", removeErr.message);
            // We can continue even if removing fails, or choose to throw.
          }
        }

        // Now upload the new file
        updatedPdfUrl = await uploadFileToSupabase(newFile, editData);
      }

      const dataToSend = {
        CustomerOrderNumber: editData.orderNumber,
        OrderNumber: editData.dbOrderNumber,
        PickupDate: editData.pickupDate,
        PickupLocation: editData.pickupLocation,
        DeliveryDate: editData.deliveryDate,
        DeliveryLocation: editData.deliveryLocation,
        Price: editData.rate,
        Currency: editData.currency,
        PriceKept: editData.priceGiven,
        TripType: editData.tripType,
        CarrierOrEquipment: editData.equipment,
        Status: editData.status,
        AccountingEmail: editData.accountingEmail,
        DispatchEmail: editData.dispatcherEmail,
        PickupAddress: JSON.stringify(editData.pickups || []),
        DeliveryAddress: JSON.stringify(editData.deliveries || []),
        CompanyAddress: JSON.stringify(editData.loadGivingCompany || {}),
        DateAdded: editData.dateAdded,
        Pdf: updatedPdfUrl,
      };

      const response = await fetch("/api/update-load", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedData = await response.json();
      console.log("Order updated:", updatedData);
      alert("Order updated successfully!");
      // navigate("/order-history");
      // NOW decide where to go based on 'fromPage':
      if (fromPage === "invoicing") {
        // Option A: navigate directly back to the invoicing page for this order:
        navigate(`/invoicing/${editData.dbOrderNumber}`);
        // Option B: or just go back one step if that is guaranteed to be Invoicing:
        // navigate(-1);
      } else {
        // If fromPage was "orderHistory" or anything else, go to order history
        navigate("/order-history");
      }
    } catch (error) {
      console.error("Failed to update order:", error);
      alert(`Error updating order: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Uploads file to Supabase with "upsert: true" so it replaces (or creates) a new file.
   * We generate a unique name based on company, orderNumber, rate, etc.
   */
  const uploadFileToSupabase = async (file, editDataObj) => {
    try {
      // Figure out extension
      const fileName = file.name;
      const extension = fileName.includes(".")
        ? fileName.split(".").pop().toLowerCase()
        : "pdf";

      // Generate a new or consistent filename
      const sanitizedCompanyName = editDataObj.loadGivingCompany.companyName
        .trim()
        .replace(/\s+/g, "-");
      const sanitizedOrderNumber = editDataObj.orderNumber
        .toString()
        .replace(/\s+/g, "-");
      const sanitizedRate = editDataObj.rate.toString().replace(".", "-");

      const storageFileName = `${sanitizedCompanyName}-${sanitizedOrderNumber}-${sanitizedRate}.${extension}`;

      // Upload to Supabase: "pdf-storage" bucket, folder "MG Transport LC/"
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("pdf-storage")
        .upload(`MG Transport LC/${storageFileName}`, file, {
          upsert: true,
        });

      if (uploadError || !uploadData) {
        throw new Error(
          `Error uploading file to Supabase: ${uploadError?.message}`
        );
      }

      // Get public URL
      const { data: publicURLData } = supabase.storage
        .from("pdf-storage")
        .getPublicUrl(`MG Transport LC/${storageFileName}`);

      if (!publicURLData || !publicURLData.publicUrl) {
        throw new Error("Unable to retrieve public URL from Supabase.");
      }

      return publicURLData.publicUrl;
    } catch (err) {
      console.error("uploadFileToSupabase error:", err);
      throw err;
    }
  };

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setNewFile(file);
  };

  const handleDownloadFile = async () => {
    const fileUrl = editData?.pdfUrl;
    if (!fileUrl) return;

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(blob);

      // Attempt to get a file extension based on the Content-Type
      const contentType = response.headers.get("content-type") || "";
      let fileExtension = "pdf"; // Default

      if (contentType.includes("pdf")) {
        fileExtension = "pdf";
      } else if (contentType.includes("image/png")) {
        fileExtension = "png";
      } else if (contentType.includes("image/jpeg")) {
        fileExtension = "jpg";
      } else if (
        contentType.includes("application/msword") ||
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        fileExtension = "docx";
      }

      // Default filename
      let suggestedFilename = `${
        editData.loadGivingCompany.companyName || "order"
      }-details.${fileExtension}`;

      // If the server sets a "content-disposition" filename, use that
      const contentDisposition = response.headers.get("content-disposition");
      if (contentDisposition && contentDisposition.includes("filename=")) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match?.[1]) {
          suggestedFilename = match[1];
        }
      }

      const link = document.createElement("a");
      link.href = fileURL;
      link.download = suggestedFilename;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert(`Error downloading file: ${error.message}`);
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <SideNavBar />
        <div className="edit-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-body">
            <div className="spinner-container">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!editData) {
    return (
      <div className="container">
        <SideNavBar />
        <div className="edit-window">
          <Toolbar style={{ minHeight: "83px" }} />
          <div className="edit-body">
            <p>No data to edit.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <SideNavBar />
      <div className="edit-window">
        <Toolbar style={{ minHeight: "83px" }} />
        <div className="edit-body">
          <OrderDetails
            orderNumber={editData.orderNumber}
            setOrderNumber={(val) =>
              setEditData((prev) => ({ ...prev, orderNumber: val }))
            }
            pickupDate={editData.pickupDate}
            setPickupDate={(val) =>
              setEditData((prev) => ({ ...prev, pickupDate: val }))
            }
            pickupLocation={editData.pickupLocation}
            setPickupLocation={(val) =>
              setEditData((prev) => ({ ...prev, pickupLocation: val }))
            }
            deliveryDate={editData.deliveryDate}
            setDeliveryDate={(val) =>
              setEditData((prev) => ({ ...prev, deliveryDate: val }))
            }
            deliveryLocation={editData.deliveryLocation}
            setDeliveryLocation={(val) =>
              setEditData((prev) => ({ ...prev, deliveryLocation: val }))
            }
            rate={editData.rate}
            setRate={(val) => setEditData((prev) => ({ ...prev, rate: val }))}
            currency={editData.currency}
            setCurrency={(val) =>
              setEditData((prev) => ({ ...prev, currency: val }))
            }
            priceGiven={editData.priceGiven}
            setPriceGiven={(val) =>
              setEditData((prev) => ({ ...prev, priceGiven: val }))
            }
            tripType={editData.tripType}
            setTripType={(val) =>
              setEditData((prev) => ({ ...prev, tripType: val }))
            }
            equipment={editData.equipment}
            setEquipment={(val) =>
              setEditData((prev) => ({ ...prev, equipment: val }))
            }
            status={editData.status}
            setStatus={(val) =>
              setEditData((prev) => ({ ...prev, status: val }))
            }
            accountingEmail={editData.accountingEmail}
            setAccountingEmail={(val) =>
              setEditData((prev) => ({ ...prev, accountingEmail: val }))
            }
            dispatcherEmail={editData.dispatcherEmail}
            setDispatcherEmail={(val) =>
              setEditData((prev) => ({ ...prev, dispatcherEmail: val }))
            }
            pickups={editData.pickups}
            setPickups={(val) =>
              setEditData((prev) => ({ ...prev, pickups: val }))
            }
            deliveries={editData.deliveries}
            setDeliveries={(val) =>
              setEditData((prev) => ({ ...prev, deliveries: val }))
            }
            loadGivingCompany={editData.loadGivingCompany}
            setLoadGivingCompany={(val) =>
              setEditData((prev) => ({
                ...prev,
                loadGivingCompany: val,
              }))
            }
            onSave={handleSave}
          />

          <div style={{ marginTop: "20px" }}>
            {editData.pdfUrl ? (
              <Button
                variant="contained"
                color="success"
                onClick={handleDownloadFile}
                style={{ marginRight: "10px" }}
              >
                Download File
              </Button>
            ) : (
              <p>No file available for this order.</p>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleFileButtonClick}
            >
              Upload New File
            </Button>
            <input
              type="file"
              accept="
                application/pdf,
                image/*,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document
              "
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />

            {newFile && (
              <div style={{ marginTop: "10px" }}>
                <strong>New file selected:</strong> {newFile.name}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
